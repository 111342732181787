<template>
  <div style="" class="news-container">
    <div class="news-top">
      <div style="height: 10px; background-color: #ff8c26"></div>
      <div class="top-title">{{ newsDetail.title }}</div>
      <div class="top-txt">日期：{{ newsDetail.date }}</div>
    </div>
    <div class="news-center">
      <div class="news-desc">
        {{ newsDetail.desc }}
      </div>
      <div style="width: 30%; height: auto; margin: auto">
        <img
          :src="newsDetail.image"
          alt=""
          style="width:100%; height: 100%"
        />
      </div>
    </div>
    <div class="news-bottom">
      <div style="height: 20px; background-color: #ffffff"></div>
      <div v-html=" myContent()"></div>
      <div style="height: 20px; background-color: #ffffff"></div>
    </div>
  
  </div>
</template>

<script>
import ContactNow from "./ContactNow.vue";
export default {
  name: "MbNewsDetail",
  components: {
    ContactNow,
  },
  data() {
    return {
      id: 0,
      newsDetail: {},
    };
  },
  mounted() {
    console.log("路由参数", this.$route.query.id);
    this.id = this.$route.query.id;
    this.getNewsinfoByid();
    this.toTop();
  },
  methods: {
    myContent(){
const myhtml = this.newsDetail.content.replace(/<img([\s\w"-=\/\.:;]+)((?:(height="[^"]+")))/ig, '<img$1')
.replace(/<img([\s\w"-=\/\.:;]+)((?:(width="[^"]+")))/ig, '<img$1')
.replace(/<img([\s\w"-=\/\.:;]+)((?:(style="[^"]+")))/ig, '<img$1')
.replace(/<img([\s\w"-=\/\.:;]+)((?:(alt="[^"]+")))/ig, '<img$1')
.replace(/<img([\s\w"-=\/\.:;]+)/ig, '<img style="width: 100%;" $1')
.replace(/\<p/gi, '<p style="margin-block-start: 0;margin-block-end: 0;"');

    
return myhtml;
    },
    async getNewsinfoByid() {
      let res = await this.$API.news.getNewsinfoByid(this.id);
      if (res.code == 200) {
        this.newsDetail = res.data;
      } else {
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.news-desc {
  margin: 10px auto;
  text-align: left;
  width: calc(70% - 20px);
  /* height: 112px; */
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
}
.top-title {
  text-align: left;
  width: calc(100% - 130px);
  margin: 0 auto;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  /* line-height: 51px; */
}
.top-txt {
  /* margin: 50px auto 0; */
  text-align: left;
  width: calc(100% - 130px);
    margin: 0 auto;

  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  /* line-height: 51px; */
}
.news-container {
  width:100%;
  margin:0 auto;
  background-color: #f2f2f2;
}
.news-top {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #ff8c26;
}
.news-center {
  display: flex;
  /* z-index: 9; */
  position: absolute;
  top: 150px;
  left:0;
 width:calc(100% - 40px);
  margin: 0 20px;
  height:auto;
  background-color: #ffffff;
}
.news-bottom {
  width:100%;
  margin: 100px 0;
  background: #ffffff;
}
</style>