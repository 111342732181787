import request from '@/utils/request'

export const getBannerList=()=>request({
    url:'/banner_list',
    method:'get',
})
// 上传图片
export const getPhoto=(photo)=>request({
    url:'/upload_img',
    method:'post',
    data:photo
})

// 添加轮播图信息
export const addBanner=(banner)=>request({
    url:'/banner_add',
    method:'post',
    data:banner
})

// 修改轮播图信息
export const updateBanner=(banner)=>request({
    url:'/banner_edit',
    method:'post',
    data:banner
})

// 删除轮播图信息
export const deleteBanner=(banner)=>request({
    url:'/banner_del',
    method:'post',
    data:{id:banner.id}
})

export const addOrUpdateBanner=(banner)=>{
    if(banner.id){
        return  request({url:'/banner_edit',method:'post', data:banner})
    }else{
        return  request({url:'/banner_edit',method:'post', data:banner})

    }
}
