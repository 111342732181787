<template>
  <div style="margin-top: 208px">
    <!-- 即刻联系→ -->
    <!-- <div style="margin: 100px 0">
      <ContactNow />
    </div> -->
    <!-- 案例鉴赏 -->
    <div class="works-box">
      <div class="box-top">
        <div>
          <div class="title">最新动态</div>
          <div class="subtitle">
            <div class="txt">NEWS</div>
            <div class="line" style="width: 150px"></div>
          </div>
        </div>
        <!-- <div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            text-color="#000"
            active-text-color="#FF8C26"
          >
            <el-menu-item @click="getWorksList" index="1-1">ALL </el-menu-item>

            <el-menu-item
              v-for="(item, index) in navList.slice(0, 7)"
              :key="index"
              @click="getworkListByClass(item)"
              :index="item.class_name"
              >{{ item.class_name }}</el-menu-item
            >
          </el-menu>
        </div> -->
      </div>
      <div class="box-content">
        <div class="works-list">
          <div
            class="works-item"
            v-for="(item, index) in NewsList"
            :key="index"
            @click="toNewsDetail(item)"
          >
            <div class="works-top">
              <div class="works-img">
                <img
                  :src="item.cover"
                  style="width: 100%; height: 100%"
                  alt=""
                  class="fangda"
                />
              </div>
              <div class="mask"></div>
            </div>

            <div class="works-bottom">
              <div class="works-tit">{{ item.title }}</div>
              <!-- <div class="works-content">
                <div class="works-time">{{ item.subtitle }}</div>
                <div class="works-scord">
                  <div
                    style="width: 18px; height: 18px"
                    v-for="star in 5"
                    :key="star"
                  >
                    <img
                      src="../assets/star.png"
                      style="width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="box-bottom">
        <!-- <div class="more-btn">
          <img src="../assets/more.png" alt="">
        </div> -->
      </div>
    </div>
    <!-- 合作伙伴 -->
    <!-- <div class="partner-box">
      <div class="partner-tit">
        <div class="tit1">合作伙伴</div>
        <div class="tit2">COOPERATIVE PARTNER</div>
      </div>
      <div class="partner-list">
        <div class="partner-item" v-for="index in 12" :key="index">
          <img
            alt=""
            :src="require('@/assets/logoList/logo' + index + '.png')"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div> -->
    <!-- <ContactNow /> -->
    <div
      style="height: 100px; background-color: #ffffff; margin-top: 50px"
    ></div>
  </div>
</template>

<script>
// import ContactNow from "./ContactNow.vue";

export default {
  name: "NewsList",
  // components: {
  //   ContactNow,
  // },
  data() {
    return {
      id: 0,
      // navList: [],
      NewsList: [
       
      ],
      // activeIndex: "1-1",
    };
  },
  methods: {
    // 导航栏点击事件
    handleSelect(key, keyPath) {},
    
     toNewsDetail(item) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "NewsDetail", query: { id: item.id } });
    },

     async getNewsList() {
      let res = await this.$API.news.getNewsList();
      if (res.code == 200) {
        this.NewsList = res.data;
        console.log("最新动态", this.NewsList);
      }
    },
    // async getWorkClassList() {
    //   let res = await this.$API.works.getWorkClassList();
    //   if (res.code == 200) {
    //     this.navList = res.data;
    //   }
    // },
    // async getWorksList() {
    //   let res = await this.$API.works.getworkListByClass({ id: 0 });
    //   if (res.code == 200) {
    //     this.worksList = res.data;
    //   }
    // },

    // async getworkListByClass(item) {
    //   let res = await this.$API.works.getworkListByClass(item);
    //   if (res.code == 200) {
    //     this.worksList = res.data;
    //   }
    // },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.toTop();
    this.getNewsList();
    // this.getWorkClassList();

    // console.log("路由参数", this.$route.query.id);
    // this.id = this.$route.query.id;
    // this.activeIndex=this.$route.query.classname
    // console.log("this.activeIndex", this.activeIndex);
    // if (this.$route.query.id) {
    //   this.getworkListByClass({ id: this.id });
    // } else {
    //   this.getWorksList();
    // }
  },
};
</script>

<style scoped>
.works-box{
  margin:100px auto;
}
.el-menu-item {
  height: 64px;
  font-size: 16px;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item.is-active {
  font-size: 18px;
  color: #000;
  border-bottom: 2px solid #ff8c26;
}
.works-list {
  width: 1520px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
}
.works-item {
  width: 411px;
  margin: 10px 45px;
  /* padding:10px; */
  box-sizing: border-box;
}
.works-img {
  width: 411px;
  height: 291px;
  overflow: hidden;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 411px;
  height: 291px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.works-top:hover .mask {
  cursor: pointer;
  display: block;
}
.fangda {
  transition: all 0.6s;
}
.works-top:hover .fangda {
  transform: scale(1.2);
  cursor: pointer;
}
.works-tit {
  overflow: hidden;
  white-space: nowrap; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  margin: 10px 0;
  box-sizing: border-box;
  text-align: left;
  height: 26px;
  font-size: 21px;
  font-family:  SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #202016;
  line-height: 26px;
}
.works-time {
  /* margin:10px 0; */
  box-sizing: border-box;
  text-align: left;
  height: 18px;
  font-size: 14px;
  font-family:  SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #727272;
  line-height: 18px;
}
.works-scord {
  display: flex;
  height: 18px;
  line-height: 18px;
}
.works-top {
  position: relative;
  box-sizing: border-box;
  width: 411px;
}
.works-bottom {
  padding: 0 10px;
  box-sizing: border-box;
}
.works-content {
  display: flex;
  justify-content: space-between;
}

.box-top {
  width: 1520px;
  margin: 20px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: left;
  height: 37px;
  font-size: 28px;
  font-family:  SourceHanSansCN-bold;
  font-weight: bold;
  color: #212121;
  line-height: 37px;
}
.subtitle {
  position: relative;
  width: 120px;
  height: 31px;
}
.txt {
 z-index: 9;
  position: absolute;
  top: -18px;
  height: 31px;
  font-size: 24px;
  font-family:  SourceHanSansCN-bold;
  font-weight: bold;
  color: #424242;
  line-height: 68px;
}
.line {
  position: absolute;
  bottom: 0;
  text-align: left;
  height: 12px;
  background: #ff8c26;
}
.partner-box {
  margin: 100px 0;
}
.partner-tit {
}
.tit1 {
  height: 34px;
  font-size: 36px;
  font-family:  SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #333333;
  line-height: 1px;
}
.tit2 {
  height: 19px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 400;
  color: #666666;
  line-height: 1px;
}
.partner-list {
  width: 1520px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partner-item {
  margin: 30px 0;
  width: 444px;
  height: 119px;
  background: #ffffff;
  /* border: 1px solid #A0A0A0; */
  border-radius: 58px;
}
</style>