<template>
  <div>
    <div>
      <Mbbanner />
      <!-- 公司简介 -->
      <div style="width: calc(100% - 67px); margin: 0 auto">
        <div class="txt1">ABOUT US</div>
        <div>
          <div class="txt2">南京创心天降文化创意有限公司</div>
          <div class="txt2">
            天降文化”成立于2015年7月，品牌名称源自“故天将降大任于斯人也”。公司以创造性的视觉创作为出发点，以全方位的视角进行设计实践，致力于打造一间有温度的视觉艺术工作室。
          </div>
          <div class="txt2">
            服务范畴为IP吉祥物全案、插画/漫画/绘本、表情/动画/视频、文创礼盒/产品开发、品牌/策划咨询、VI/空间全案等。
          </div>
          <div class="txt2">
            在视觉日益同质化的互联网时代，“天降文化”笃信创意的核心价值，是不易被公式化的设计所淹没的。我们将不断修正和精进每一个服务流程，为您的品牌提升，量身打造最优质的创意方案！
          </div>
        </div>
        <div style="width: calc(100% - 22px); margin: 0 auto">
          <img
            src="../assets/photo.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <!-- 最新动态 -->
      <div style="width: calc(100% - 67px); margin: 0 auto">
        <div class="txt1">最新动态</div>
        <div class="news-list">
          <div
            class="news-item"
            v-for="(item, index) in newsList.slice(0, 3)"
            :key="index"
            @click="toNewsDetail(item)"
          >
            <div class="news-top">
              <div class="news-img">
                <img
                  :src="item.cover"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
            </div>

            <div class="news-bottom">
              <div class="news-tit">{{ item.title }}</div>
              <!-- <div class="news-content">
                <div class="news-time">{{ item.subtitle }}</div>
                <div class="news-scord">
                  <div
                    style="width: 9px; height: 9px"
                    v-for="star in 5"
                    :key="star"
                  >
                    <img
                      src="../assets/star.png"
                      style="width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="more" @click="toNewsList">
          <img
            src="../assets/more.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <partner />
    </div>
  </div>
</template>

<script>
import partner from "./partner.vue";
import Mbbanner from "./Mbbanner.vue";

export default {
  name: "MbIntroduction",
  components: {
    partner,
    Mbbanner,
  },
  data() {
    return {
      newsList: [],
    };
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      let res = await this.$API.news.getNewsList();
      if (res.code == 200) {
        this.newsList = res.data;
        console.log("最新动态", this.newsList);
      }
    },
    toNewsDetail(item) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "MbNewsDetail", query: { id: item.id } });
    },
    toNewsList() {
      this.$router.push("/MbNewsList");
    },
  },
};
</script>

<style scoped>
.txt1 {
  text-align: left;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  padding: 20px 0;
}
.txt2 {
  text-align: left;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #212121;
  line-height: 25px;
  margin: 0 0 20px 0;
}
.news-list {
}
.news-item {
  width: calc(100% - 20px);
  margin: 10px;
}
.works-img {
  width: 100%;
  height: 85px;
  overflow: hidden;
}

.news-tit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  margin: 0 0 30px 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #202016;
}
.works-time {
  /* margin:10px 0; */
  box-sizing: border-box;
  text-align: left;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}
.works-scord {
  display: flex;
  height: 9px;
  line-height: 9px;
}
.works-top {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.works-bottom {
}
.works-content {
  display: flex;
  justify-content: space-between;
}
.more {
  width: calc(100% - 208px);
  margin: 20px auto;
}
</style>