import request from '@/utils/request'
// 查询最新动态
export const getNewsList=()=>request({
    url:'/index_news_list',
    method:'get',
})
export const getNewsinfoByid=(newsid)=>request({
    url:'/index_news_info',
    method:'get',
    params: { id:newsid }
})
