// 将4个模块的接口函数统一暴露
import * as banner from "./banner.js";
import * as system from "./system.js";
import * as business from "./business.js";
import * as works from "./works.js";
import * as news from "./news.js";



export default{
    banner,
    system,
    business,
    works,
    news


}

