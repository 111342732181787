<template>
  <div style="margin-top: 108px">
    
    
    <div v-if="showWorkDetail">
      <!-- 案例封面 -->
      <div class="cover-box"  v-if="videoArr.length==0">
        <img
          :src="workDetail.cover"
          alt=""
          style="width: 1516px; margin: 0 auto"
        />
      </div>
      <!-- 案例长图 -->
      <div class="content-box">
        <div v-if="videoArr.length!=0">
            <video v-for="(item,index) in videoArr" :key="index"  :src="item" type=""  :autoplay="true" controls="controls"
                    muted
                    loop
          style="width: 1516px; height: auto; margin: -3px auto"
></video>
        </div>
       
        <img
          :src="item"
          alt=""
          v-for="(item, index) in imgArr"
          :key="index"
          style="width: 1516px; height: auto; margin: -3px auto"
        />
      </div>
    </div>

    <!-- 相关案例 -->
    <div style="width: 1520px; margin: 0 auto">
      <!-- 顶部标题 -->
      <div style="margin-top: 50px">
        <div class="title">相关案例</div>
        <div class="subtitle">
          <div class="txt">Related Cases</div>
          <div class="line" style="width: 160px"></div>
        </div>
      </div>
      <!-- 案列列表   -->
      <div class="award-list" style="margin: 50px 0 0 0" v-if="showWorkDetail">
        <div
          class="award-item"
          v-for="(item, index) in newsList.slice(0, 3)"
          :key="index"
          @click="frashWorks(item)"
        >
          <div class="item-img">
            <img
              :src="item.cover"
              alt=""
              style="width: 100%; height: 100%; cursor: pointer"
              class="fangda"
            />
          </div>
          <div class="mask"></div>
          <!-- 获奖项目 -->
          <div style="padding: 20px 0">
            <div class="subtitle1">
              <div class="txt1">{{ item.title }}</div>
              <div class="line1" ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- more -->
      <div>
        <div class="more-btn" @click="toworkList" style="cursor: pointer">
          <img src="../assets/more.png" alt="" />
        </div>
      </div>
    </div>
     <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
    <ContactNow/>
    <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
  </div>
</template>

<script>
import ContactNow from "./ContactNow.vue"

export default {
  name: "workDetail",
  components:{
    ContactNow,
  },
  mounted() {
    console.log("路由参数", this.$route.query.id,this.$route.query.workclass);
    this.id = this.$route.query.id;
    this.workClass=this.$route.query.workclass;
    // this.getnewsListByClass({ id: this.workClass });
    this.getworkinfoByid();
    this.toTop();
    this.matchType();
  },
  data() {
    return {
      showWorkDetail: true,
      newsList: [],
      workDetail: {},
      imgArr:[],
      videoArr:[],
      id: 0,
      workClass:0
    };
  },
  methods: {
  matchType(){
    this.imgArr=[]
    this.videoArr=[]
    console.log("方法执行")
     this.workDetail.content.forEach(item => {
          let suffix=''
          let fileArr=item.split('.')
          suffix=fileArr[fileArr.length-1];
          let imgList=['jpg','jpeg','png','gif','bmp']
          imgList.some(it=>{
            if(it==suffix){
              this.imgArr.push(item)
            }
          })
          let videoList=['mp4','m2v','mkv','ofd']
          videoList.some(it=>{
            if(it==suffix){
              this.videoArr.push(item)
            }
          })
     });
     console.log("图片",this.imgArr,"视频",this.videoArr)

  },






    toTop() {
      document.documentElement.scrollTop = 0;
    },
    toworkList() {
      this.$router.push("/WorkList");
    },
    async frashWorks(item) {
      let res = await this.$API.works.getworkinfoByid(item.id);
      if (res.code == 200) {
        this.workDetail = res.data;

        this.matchType()
        console.log(this.workDetail);
                        this.newsList=res.data.xiangguananli

        this.showWorkDetail = false;
        this.$nextTick(() => {
          this.showWorkDetail = true;
          console.log("已经刷新");
          this.toTop();
        });
      } else {
      }
    },
    async getworkinfoByid() {
      let res = await this.$API.works.getworkinfoByid(this.id);
      if (res.code == 200) {
        this.workDetail = res.data;
                this.newsList=res.data.xiangguananli

        this.matchType()
        console.log(this.workDetail);
      } else {
      }
      //  if(res=200){

      //  }
    },
    toworkDetail(id) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "WorkDetail", query: { id: id } });
    },
    // async getnewsListByClass(item) {
    //           console.log("最新动态", item);

    //   let res = await this.$API.works.getworkListByClass(item);
    //   if (res.code == 200) {
    //     this.newsList = res.data;
    //     console.log("最新动态", this.newsList);
    //   }
    // },
  },
};
// this.$route.query.id
</script>

<style scoped>
.content-box {
  /* display: flex;
  flex-wrap: wrap; */
}
.award-list {
  width: 1520px;
  /* margin: 0 auto; */
  height: 400px;
  display: flex;
  justify-content: left;
}
.award-item {
  width: 20%;
  position: relative;
  margin:0 80px ;
}
.item-img {
  width: 411px;
  height: 291px;
  overflow: hidden;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 411px;
  height: 291px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.award-item:hover .mask {
  cursor: pointer;
  display: block;
}
.fangda{
  transition: all 0.6s;
}
.award-item:hover .fangda {
  transform: scale(1.2);
  cursor: pointer;
}
.title {
  text-align: left;
  height: 48px;
  font-size: 32px;
  font-family:SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
  line-height: 48px;
}
.subtitle {
  position: relative;
  min-width: 210px;
  height: 31px;
}
.txt {
  z-index: 9;
  position: absolute;
  top: -18px;
  height: 31px;
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 68px;
}
.line {
  position: absolute;
  bottom: 0;
  text-align: left;
  height: 10px;
  background: #ff8c26;
}
.subtitle1 {
  position: relative;
  width: 210px;
  height: 31px;
}
.txt1 {
    z-index: 9;

  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  position: absolute;
  top: 0;
  height: 24px;
  font-size: 20px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 24px;
}
.line1 {
  position: absolute;
  width: 100%;

  bottom: 10px;
  text-align: left;
  height: 10px;
  background: #ff8c26;
  opacity: 0.33;
}
</style>