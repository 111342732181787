<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?3186347b3ca59e836d6b7f647951423e";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
import DevicePixelRatio from "./utils/devicePixelRatio";

export default {
  name: "App",
  created() {
    new DevicePixelRatio().init();
    // this.$alert(this.$route.);
    // if (this._isMobile()) 
    // {
    //   //手机入口s
    //   // this.$alert(window.location.href);
    //   let url = window.location.href;

    //   if(url.indexOf("/Mb") == -1){
    //     let newurl = url.replace("#/", "#/Mb");
    //     window.location.href =newurl;
    //     // this.$alert(newurl);
    //   }else{
    //     let newurl = url;
    //     window.location.href =newurl;
    //     // this.$alert(newurl);
    //   }
    // }else{
    //   //电脑XX
    //   let url = window.location.href;
    //   if(url.indexOf("/Mb") == -1){
    //     let newurl = url;
    //     window.location.href =newurl;
    //     console.log(decodeURIComponent('%5Bobject%20Object%5D'));

    //   }else{
    //     let newurl = url.replace("/Mb", "/");
    //     window.location.href =newurl;
    //     console.log(window.location.href);

    //   }
    // }

    if (this._isMobile()) {
      console.log("手机设备");
      if (this.$route.path.indexOf("Mb") == -1) {
        // this.$alert(window.location.href);
        const oldPath = this.$route.path;//#NewsDetail
        let newPath = oldPath.replace("/", "/Mb");
        console.log("拼接的新地址", newPath);
        if (this.$route.query.id) {
          this.$router.replace({
            path: newPath,
            query: { id: this.$route.query.id,workclass: this.$route.query.workclass },
          });
        } else {
          this.$router.replace(newPath);
        }
      } else {
        
      }
    } else {
      console.log("pc端");
      if (this.$route.path.indexOf("Mb") == -1) {
      } else {
        const oldPath = this.$route.path;
        let newPath = oldPath.replace("/Mb", "/");
        console.log("旧地址", oldPath);
        console.log("拼接的新地址", newPath);
         if (this.$route.query.id) {
          this.$router.replace({
            path: newPath,
            query: { id: this.$route.query.id,workclass: this.$route.query.workclass },
          });
        } else {
          this.$router.replace(newPath);
        }
      }
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.showMobile = flag;
      return flag;
    },
  },
};
</script>

<style scoped >
#app {
  overflow-x: hidden;
  transform-origin: center center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
