<template>
  <div style="margin:0 auto ">
<!-- 即刻联系 -->
        <div class="contact-box">
        <div class="contact-img">
          <img alt="" src="../assets/logo.png" style="width:100%; height:100%">
        </div>
        <div class="contact-txt">天降文化是一家动漫IP品牌设计公司，我们以市场需求为导向，为企业提供一站式的创新解决方案。如需设计咨询，请联系我们专家团队！</div>
        <div class="contact-btn">
        <div class="contact-btn-txt" @click="toLink()">
           即刻联系
        </div>  
        <div style="width:27px ;height:12px;line-height: 62px;">
          <img alt="" src="../assets/btn-icon.png" style="width:100%; height:100%">
        </div>
        </div>
       
        </div>
       










  </div>
</template>

<script >
export default {
  name:"ContactUs",
  methods:{
    toLink(item){
        console.log("即刻联系点击事件")
        window.location.href =  "https://affim.baidu.com/unique_49827639/chat?siteId=19540633&userId=49827639&siteToken=3186347b3ca59e836d6b7f647951423e";
   }
  }
}
</script>

<style scoped>
.contact-box{
box-sizing:border-box;
margin: 0 auto; 
padding: 94px 91px 108px 81px;
width: 1520px;
height: 260px;
background: #FFFFFF;
box-shadow: 0px 12px 40px 0px rgba(0,0,0,0.09);
border-radius: 10px;
display: flex;
justify-content: space-between;
}
.contact-img{
width: 189px;
height: 57px;
}
.contact-txt{
width: 806px;
height: 58px;
text-align: left;
font-size: 22px;
font-family:SourceHanSansCN-Regular;
font-weight: 400;
color: #828282;
line-height: 36px;
}
.contact-btn{
padding: 0 auto;
box-sizing:border-box;
display: flex;
justify-content: center;
width: 172px;
height: 62px;
border-radius: 33.5px;
background-color: #F55639 ;
}
.contact-btn-txt{
cursor: pointer;

text-align: center;
height: 62px;
line-height: 62px;

color: #ffffff;
font-size: 24px;
font-family:SourceHanSansCN-Medium;
font-weight: 500;

}
</style>