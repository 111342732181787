<template>
  <div style="margin-top: 108px" class="news-container">
    <div class="news-top">
      <div style="height: 10px; background-color: #ff8c26"></div>
      <div class="top-title">{{ newsDetail.title }}</div>
      <div class="top-txt">日期：{{ newsDetail.date }}</div>
    </div>
    <div class="news-center">
      <div class="news-desc">
        {{ newsDetail.desc }}
      </div>
      <div style="width: 300px; height: 300px; margin: auto">
        <img
          :src="newsDetail.image"
          alt=""
          style="width: 300px; height: 300px"
        />
      </div>
    </div>
    <div class="news-bottom">
      <div style="height: 20px; background-color: #ffffff"></div>
      <div v-html="newsDetail.content"></div>
      <div style="height: 20px; background-color: #ffffff"></div>
    </div>
    <!-- 即刻联系 -->
    <ContactNow />
    <div
      style="height: 40px; background-color: #f2f2f2; margin-top: 50px"
    ></div>
  </div>
</template>

<script>
import ContactNow from "./ContactNow.vue";
export default {
  name: "NewsDetail",
  components: {
    ContactNow,
  },
  data() {
    return {
      id: 0,
      newsDetail: {},
    };
  },
  mounted() {
    console.log("路由参数", this.$route.query.id);
    this.id = this.$route.query.id;
    this.getNewsinfoByid();
    this.toTop();
  },
  methods: {
    async getNewsinfoByid() {
      let res = await this.$API.news.getNewsinfoByid(this.id);
      if (res.code == 200) {
        this.newsDetail = res.data;
      } else {
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.news-desc {
  margin: auto;
  text-align: left;
  width: 829px;
  height: 112px;
  font-size: 28px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #000000;
  line-height: 42px;
}
.top-title {
  margin: 50px auto 0;
  text-align: left;
  width: 1300px;
  font-size: 52px;
font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #ffffff;
  line-height: 51px;
}
.top-txt {
  margin: 50px auto 0;
  text-align: left;
  width: 1300px;
  font-size: 24px;
  font-family:  SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #ffffff;
  line-height: 51px;
}
.news-container {
  background-color: #f2f2f2;
}
.news-top {
  position: relative;
  width: 1920px;
  height: 580px;
  background-color: #ff8c26;
}
.news-center {
  display: flex;
  z-index: 9;
  position: absolute;
  top: 383px;
  left: calc((100% - 1283px) / 2);
  margin: 0 auto;
  width: 1300px;
  height: 383px;
  background-color: #ffffff;
}
.news-bottom {
  margin: 100px auto 100px auto;
  box-sizing: border-box;
  width: 1300px;
  background: #ffffff;
}
</style>