<template>
  <div style="margin-top: 108px">
    <!-- banner -->
    <!-- <div>
      <Banner />
    </div> -->
    <!-- service -->
    <div class="content-box">
      <div class="content-left">
        <div class="left-txt">天降文化</div>
        <div class="left-txt">服务内容体系</div>
      </div>
      <div class="content-center">
        <div>
          <pre class="center-txt1">{{ txt }}</pre>
        </div>
        <div class="center-txt2">
          为注重品牌IP形象和视觉战略的高要求客户，提供一站式的视觉系统设计方案。
        </div>
        <div class="center-txt3">
          To provide a one-stop visual system design solution for high demand
          customers who focus on brand IP image and visual strategy.
        </div>
      </div>
      <div class="content-right">
              <video  src="../assets/main.mp4" type=""  :autoplay="true" 
                    muted
                    loop
         class="img-box"
></video>
       
      </div>
    </div>

    <!-- 业务列表 -->
    <div class="service-list">
      <div
        class="service-item"
        v-for="(item, index) in serviceList"
        :key="index"
        @click="toUrl(item)"
      >
        <div class="item-left">
          <div class="item-img">
            <img :src="item.cover" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>
        <div class="item-right">
          <div class="item-tit">{{ item.title }}</div>
          <div class="item-txt">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <ContactNow/>
   <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
  </div>
</template>

<script>
import Banner from "./banner.vue";
import ContactNow from "./ContactNow.vue"

export default {
  name: "ServiceBusiness",
  components: {
    Banner,
    ContactNow
  },
  data() {
    return {
      txt: "Tian Jiang Culture\nService\nContent system",
      // 业务列表
      serviceList: [
        {
          url: "",
          title: "IP文化搭建",
          text: "打造具有地方特色、符合目标用户审美的系列IP形象及相关系列内容产品",
        },
        {
          url: "",
          title: "IP文化搭建",
          text: "打造具有地方特色、符合目标用户审美的系列IP形象及相关系列内容产品",
        },
        {
          url: "",
          title: "IP文化搭建",
          text: "打造具有地方特色、符合目标用户审美的系列IP形象及相关系列内容产品",
        },
        {
          url: "",
          title: "IP文化搭建",
          text: "打造具有地方特色、符合目标用户审美的系列IP形象及相关系列内容产品",
        },
      ],
    };
  },
  methods: {
    toUrl(item){
        window.location.href =  item.url
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    async getBusinessList() {
      let res = await this.$API.business.getBusinessList();
      if (res.code == 200) {
        this.serviceList = res.data;
      }
    },
  },
  mounted() {
    this.getBusinessList();
    this.toTop();
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 728px;
  background: #ff2b2b;
}
.content-box {
  width:1500px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
}
.content-left {
      margin: 50px 0 0 0;
}
.content-center {
}
.center-txt1 {
  text-align: left;
  font-size: 33px;
  font-family: Arial;
  font-weight: 900;
  color: #212121;
  line-height: 52px;
}
.center-txt2{
  width: 616px;
text-align: left;
font-size: 32px;
font-family: SourceHanSansCN-Medium;
font-weight: Medium;
color: #212121;
line-height: 48px;
}
.center-txt3{
  margin: 50px 0;
  width: 616px;
text-align: left;
font-size:22px;
font-family: SourceHanSansCN-Normal;
font-weight: Normal;
color: #212121;
line-height: 48px;
}
.content-right {
}
.img-box {
  width: 499px;
  height: 566px;
  /* margin: auto 50px; */
}

.left-txt {

  text-align: left;
  font-size: 42px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
  line-height: 68px;
}
.line {
  text-align: left;
  width: 50px;
  height: 5px;
  background-color: orange;
  border: none;
}
.title {
  text-align: left;
  font-family: SourceHanSansCN-Regular ;
  font-weight: Regular ;
  color: #828282;
  line-height: 36px;
}

.service-list {
  width: 1520px;
  margin: 100px auto;
}
.service-item {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
  cursor: pointer;

}
.item-left {
  width: 722px;
  height: 357px;
  /* background-color: #DD605D; */
}
.item-right {
  width: 722px;
  height: 357px;
}
.item-tit {
  text-align: left;
  height: 112px;
  line-height: 34px;
  font-size: 34px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
}
.item.img {
  display: block;
  height: 100px;
  margin: 0 auto; /*图片居中*/
}
.item-txt {
  text-align: left;
  font-size: 22px;
font-family: SourceHanSansCN-Normal;
font-weight: Normal;
color: #212121;
line-height: 30px;
}
</style>