<template>
  <div style="padding: 20px 0">
    <div class="tit">案例鉴赏</div>
    <!-- 导航栏 -->
    <van-tabs
      v-model="active"
      title-active-color="#FF8C26"
      color="#FF8C26"
      @click="onClick"
    >
      <van-tab
        v-for="(item, index) in navList.slice(0, 7)"
        :title="item.class_name"
        :key="index"
      />
      <!-- 案例列表 -->
      <div>
        <div class="works-list">
          <div
            class="works-item"
            v-for="(item, index) in worksList"
            :key="index"
            @click="toworkDetail(item)"
          >
            <div class="works-top">
              <div class="works-img">
                <img
                  :src="item.cover"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
            </div>

            <div class="works-bottom">
              <div class="works-tit">{{ item.title }}</div>
              <div class="works-content">
                <div class="works-time">{{ item.subtitle }}</div>
                <div class="works-scord">
                  <div
                    style="width: 9px; height: 9px"
                    v-for="star in 5"
                    :key="star"
                  >
                    <img
                      src="../assets/star.png"
                      style="width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "MbWorkListCom",
  props:{
     ifIndex:{
        type:String,
        default:""
     }
  },
  data() {
    return {
      navList: [],
      worksList: [],
      active: 0,
    };
  },
  methods: {
    async getWorkClassList() {
      let res = await this.$API.works.getWorkClassList();
      if (res.code == 200) {
        this.navList = res.data;
      }
    },
    async getworkListByClass(item) {
      let res = await this.$API.works.getworkListByClass(item,this.ifIndex);
      if (res.code == 200) {
        this.worksList = res.data;
      }
    },
    onClick(index, title) {
      console.log(index, title);
      const item = {
        id: index + 1,
        class_name: title,
      };
      this.getworkListByClass(item);
            this.$emit("call-back-father",item.id)

    },
    toworkDetail(item) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({
        name: "MbworkDetail",
        query: { id: item.id, workclass: item.work_class },
      });
    },
  },
  mounted() {
    console.log("当前页面是否为首页", this.ifIndex)
    this.getWorkClassList();
    if (this.$route.query.id) {
      this.getworkListByClass({ id: this.$route.query.id });
      this.active = this.$route.query.id - 1;
    } else {
      this.getworkListByClass({ id: 1 });
    }
  },
};
</script>

<style scoped>
.tit {
  width: calc(100% - 40px);
  margin: 0 auto;
  text-align: left;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
}
.works-list {
  width: calc(100% - 40px);
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
}
.works-item {
  width: calc(50% - 12px);
  margin: 6px;
}
.works-img {
  width: 155.5px;
  height: 95.5px;
  overflow: hidden;
}

.works-tit {
  overflow: hidden;
  white-space: nowrap; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  margin: 10px 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #202016;
}
.works-time {
  /* margin:10px 0; */
  box-sizing: border-box;
  text-align: left;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}
.works-scord {
  display: flex;
  height: 9px;
  line-height: 9px;
}
.works-top {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.works-bottom {
}
.works-content {
  display: flex;
  justify-content: space-between;
}
</style>