// 需要对Axios进行二次封装
import axios from "axios";
//利用axios对象的方法creat去创建一个axios实例
//request 就是axios,只不过稍微配置一下
const requests = axios.create({
    baseURL:'/api',
    timeout:500000,
    responseType: 'json',
    withCredentials: false,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        //   'Authorization': `Bearer ${window.localStorage.getItem('token')}`
    }
    
})
// 响应拦截器
requests.interceptors.response.use((res)=>{
    res = res.data;
// 成功的回调函数，服务器相应的数据回来以后，响应拦截器可以检测到，可以做一些事
if(typeof res == "string"){
    const i = res.indexOf("{");
    const str = res.slice(i);
    // const arr = res.split("</a>");
    // const str = arr[arr.length-1];
    const newres = JSON.parse(str);
    res = newres;
    return res;
    // this.myList = typeof this.testList;//string/object
    // this.mytext="百度";
  }else{
    //   this.testList=res;
    //   this.mytext="非百度";
    return res;
  }

},(error)=>{
// 响应失败的回调函数
return Promise.reject( error)
},)




// 请求拦截器，在发请求之前请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config)=>{
    // config:配置对象里面有一个很重要的属性，header请求头
    return config;
})



export default requests;



