<template>
  <div class="block">
    <el-carousel height="786px" style=" width:100%;" >
      <el-carousel-item v-for="(item,index) in bannerList" :key="index" >
        <div style="height:786px;width:100%;cursor: pointer; " @click="toLink(item)">
            <img alt="" :src="item.banner_img" style="width:100%; height:100%">       
         </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
    name:"Banner",
    mounted() {
    this.getBannerList();
  },
    data() {
    return {
      bannerList:[],
      testbanner:'',

    };
  },
  methods: {
   //获取轮播图列表
    async getBannerList() {
      let res = await this.$API.banner.getBannerList();
      this.testbanner=res
      if (res.code == 200) {
        this.bannerList = res.data;
        console.log(this.bannerList)
      }
    },
   toLink(item){
        console.log("轮播图点击事件")
        window.location.href =  item.link;
   }
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
</style>