<template>
  <div>
    <div>
      <Mbbanner />
      <div style="width: calc(100% - 67px); margin: 0 auto">
        <div class="txt1">CONTACT US</div>

        <div>
          <div
            class="txt2"
            style="
              margin-bottom: 30px;
             
            "
          >
            很荣幸，因为您选择了天降文化作为您的合作伙伴。我们相信，您的选择是对我们专业能力和服务质量的最好肯定。
          </div>
          <div
            class="txt2"
            style="
              margin-bottom: 30px;
             
            "
          >
            在天降文化，我们注重的是品质和诚信，致力于为客户提供最优质的服务。我们的行事方法是简单而又直接的，我们会全力履行我们的服务承诺，并且始终以客户为中心。
          </div>
          <div
            class="txt2"
            style="
              margin-bottom: 30px;
             
            "
          >
            我们相信，通过彼此协助和信任，我们能够实现共同的目标。如果您有任何问题或疑虑，请随时与我们联系。我们期待与您合作，共同创造更加美好的未来！
          </div>
        </div>
        <div style="margin:20px 0">
          <div class="txt3">天降文化</div>
          <div class="txt3">动漫IP品牌设计</div>
        </div>
        <div>
          <div class="txt2">
            地址：江苏省南京市玄武区太平北路136-5、202-205室
          </div>
          <div class="txt2">商务咨询：159 5188 9491 （杨经理）</div>
          <div class="txt2">商务咨询：187 2400 7312 （吕经理）</div>
          <div class="txt2" style="margin: 20px 0">邮箱：674141147@qq.com</div>
        </div>
        <div style="width: 91px; border: dashed 1px #828282; margin:28px auto 0">
          <img
            alt=""
            src="../assets/qrcode.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div style="width: 91px; margin: 20px auto 0">
          <div class="txt4">微信扫一扫</div>
          <div class="txt4">详细咨询沟通</div>
        </div>
      </div>
      <partner />
    </div>
  </div>
</template>

<script>
import partner from "./partner.vue";
import Mbbanner from "./Mbbanner.vue";

export default {
  name: "MbContactUs",
  components: {
    partner,
    Mbbanner,
  },
};
</script>

<style scoped>
.txt1 {
  text-align: left;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  padding:20px 0;
}
.txt2 {
  text-align: left;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #212121;
  line-height:25px;
}
.txt3 {
  text-align: left;

  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
}
.txt4 {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #212121;
}
</style>