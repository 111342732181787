<template>
    <div class="MbContactUs">
        <div class="phone-box" @click="callphone">
           <div class="phone-icon">
            <img src="../assets/conphone.png" alt="" style="width:100%;height:100%">
           </div>
           <div class="phone-txt" >电话微信同号：15951889491</div>
        </div>
        <div class="message-box" @click="toMbLink()">
           <div class="message-icon">
            <img src="../assets/conmessage.png" alt="" style="width:100%;height:100%">
           </div>
           <div class="message-txt">在线咨询</div>
        </div>
        <van-popup v-model="phoneshow" position="bottom" :style="{ height: '20%' ,backgroundColor:'#ffffff00'}" >
      <div @click="callphone" class="popup-btn"><i class="el-icon-phone"></i>呼叫159 5188 9491</div>
      <div @click="mycallclose" class="popup-btn">取消</div>
    </van-popup>
    </div>
</template>

<script>
export default {
name:"MbContactNow",
data(){
return{
          phoneshow:false,

}
},
methods:{
    toMbLink(){
        console.log("即刻联系点击事件")
        window.location.href =  "https://affim.baidu.com/unique_49827639/mobile/chat?siteId=19540633&userId=49827639&siteToken=3186347b3ca59e836d6b7f647951423e";
    },
     mycallshow(){
      this.phoneshow=true;
    },
    callphone(){
       window.location.href = "tel:" + 15951889491;
    },
    mycallclose(){
      this.phoneshow=false;
    }
}
}
</script>

<style scoped>
.popup-btn{
  background-color: #ffffff;
  margin:20px;
  padding:10px;
  font-family: Source Han Sans CN;
  font-size: 16px;
  border-radius: 20px;

}
.MbContactUs{
width: 100%;
height: 53px;
background: #FF8C26;
display: flex;
position: fixed;
bottom:0;
}
.phone-box{
width: 60%;  
display: flex;
}
.phone-icon{
width:18px;
height:23px;
margin: 0 10px 0 ;
}
.phone-txt{
    text-align: left;

height: 16px;
font-size: 13px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 16px;
margin: auto 0;

}
.message-box{
  width: 40%;  
background-color: #FF5E26;
display: flex;
justify-content: center;
}
.message-icon{
width:19px;
height:23px;
padding:3.5px 0;
margin: 0 10px 0;

}
.message-txt{
  text-align: left;
  height: 16px;
font-size: 13px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 16px;
margin: auto 0;

}
</style>