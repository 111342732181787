<template>
  <div id="myApp">
    <PcIndex v-if="!showMobile" />
    <MbIndex v-if="showMobile" />
  </div>
</template>

<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?3186347b3ca59e836d6b7f647951423e";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
import PcIndex from "./PcIndex.vue";
import MbIndex from "./MbIndex.vue";

export default {
  name: "myApp",
  components: {
    PcIndex,
    MbIndex,
  },
  mounted() {
    const _this=this
    // this._isMobile()
    if (_this._isMobile()) {
      console.log("手机端");
      setTimeout(function() {
			// 需要执行的代码		
      _this.$router.replace("/MbIndex");
            // _this.$router.replace("/PcIndex");

      }, 1000); // 定时时间      
      
      // this.$router.replace("/PcIndex");
    } else {
        
      console.log("pc端");
      // _this.$router.replace("/MbIndex");

      _this.$router.replace("/PcIndex");
    }
  },
  data() {
    return {
      showMobile: false,
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.showMobile = flag;
      return flag;
    },
  },
};
</script>

<style scoped >
#myApp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
