<template>
  <div>
    <div>
      <Mbbanner />
      <!-- <div style="margin:20px 0 0 0"> -->
        <!-- 中文标题 -->
        <!-- <div class="txt1">天降文化服务内容体系</div> -->
        <!-- 动图 -->
        <!-- <div> -->
          <!-- <video
            src="../assets/main.mp4"
            type=""
            :autoplay="true"
            muted
            loop
            class="video-box"
          ></video> -->
        <!-- </div> -->
        <!-- 中文 -->
        <!-- <div class="txt2">
          为注重品牌IP形象和视觉战略的高要求客户，提供一站式的视觉系统设计方案。
        </div> -->
        <!-- 英文 -->
        <!-- <div class="txt3">
          To provide a one-stop visual system design solution for high demand
          customers who focus on brand IP image and visual strategy.
        </div> -->
      <!-- </div> -->
      <div class="service-list">
        <div
          class="service-item"
          v-for="(item, index) in serviceList"
          :key="index"
          @click="toUrl(item)"
        >
          <div class="item-left">
            <div class="item-img">
              <img :src="item.cover" alt="" style="width: 100%; height: 100%" />
            </div>
          </div>
          <div class="item-right">
            <div class="item-tit">{{ item.title }}</div>
            <div class="item-txt">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <partner />
    </div>
  </div>
</template>

<script>
import partner from "./partner.vue";
import Mbbanner from "./Mbbanner.vue";

export default {
  name: "MbServiceBusiness",
  components: {
    partner,
    Mbbanner,
  },
  data(){
    return {
      serviceList:[]
    }
  },
   methods: {
    toUrl(item){
      console.log("点击链接")
        let link=item.url.replace(/(workList)/gi,'MbworkList')
        console.log(link)
        window.location.href =link
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    async getBusinessList() {
      let res = await this.$API.business.getBusinessList();
      if (res.code == 200) {
        this.serviceList = res.data;
       
      }
    },
  },
  mounted() {
    this.getBusinessList();
    this.toTop();
  },
};
</script>

<style scoped>
.service-list{
  width: calc(100% - 40px);
  margin: 0 auto;
}
.service-item{
display: flex;
margin:20px 0;
}
.item-left{
width: 50%;
}
.item-img{
  width: 166px;
  
}
.item-right{
width: calc(50% - 20px);
padding:0 10px;
}
.item-tit{
  text-align: left;
font-size: 12px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #212121;
line-height:32px
}
.item-txt{
text-align: left;
font-size: 8px;
font-family: SourceHanSansCN-Normal;
font-weight: Normal;
color: #212121;
line-height:18px
}
.video-box {
  width: calc(100% - 40px);
  margin: 0 auto;
}
.txt1 {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  line-height: 35px;
}
.txt2 {
  width: calc(100% - 40px);
  margin: 0 auto;
  text-align: left;
  font-size: 11px;
  font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #212121;
  line-height: 31px;
}
.txt3 {
  width: calc(100% - 40px);
  margin: 0 auto;
  text-align: left;
  font-size: 9px;
  font-family: SourceHanSansCN-Normal;
  font-weight: Normal;
  color: #212121;
  line-height: 29px;
}
</style>