<template>
 <div>
    <div id="header" class="header">
      <div style="display: flex; justify-content: space-between">
        <div class="logo-box" @click="toHome">
          <img
            src="../assets/logo-white.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="nav-btn-box">
          <img
            src="../assets/nav-btn.png"
            alt=""
            @click="showPopup"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div>
    <div id="content" class="content">
      <!-- <MbHome v-if="showMbHome"/> -->
      <router-view
        v-wechat-title="$route.meta.title"
        v-if="isRouterActive"
      ></router-view>
    </div>
    <div id="footer" class="footer">
      <div style="width: calc(100% - 20px); margin: 0 0 0 20px">
        <!-- 品牌logo -->
        <div class="footer-logo">
          <img
            src="../assets/footer-logo.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <!-- 联系方式 -->
        <div class="tipe-box">
          <div class="tipe">南京市玄武区太平北路136-5，202-205室</div>
          <div class="tipe">邮编：210000</div>
          <div class="tipe">手机：15951889491</div>
          <div class="tipe">微信：15951889491</div>
        </div>
        <!-- 社交媒体 -->
        <div class="icon-box">
          <div class="icon" style="" @click="myqcshow(1)">
            <img
              alt=""
              src="../assets/zhanku.png"
              style="width: 32px; height: 32px; 
"
            />
          </div>
          <div class="icon" style="" @click="myqcshow(2)">
            <img
              alt=""
              src="../assets/douyin.png"
              style="width: 32px; height: 32px; 
"
            />
          </div>
          <div class="icon" style="" @click="myqcshow(3)">
            <img
              alt=""
              src="../assets/redbook.png"
              style="width: 32px; height: 32px;  
"
            />
          </div>
          <div class="icon" style="" @click="myqcshow(4)">
            <img
              alt=""
              src="../assets/weixin.png"
              style="width: 32px; height: 32px; 
"
            />
          </div>
        </div>
        <div class="class-box">
          <div style="margin: 20px 0">
            <div class="class-tit">IP形象服务</div>
            <div class="class-txt-box">
              <span class="class-txt" @click="toworkList(1)"> IP形象全案</span>
              <span class="class-txt" @click="toworkList(7)">
                VI规范设计 平面设计</span
              >

              <span class="class-txt" @click="toworkList(1)">
                商业空间设计</span
              >
            </div>
          </div>
          <div style="margin: 20px 0">
            <div class="class-tit" @click="toworkList(1)">品牌内容运营</div>
            <div class="class-txt-box">
              <span class="class-txt" @click="toworkList(2)"> 宣传动画</span>
              <span class="class-txt" @click="toworkList(4)">
                营销连载条漫</span
              >

              <span class="class-txt" @click="toworkList(5)">
                商业空间设计</span
              >
              <span class="class-txt" @click="toworkList(2)"> 创意短视频</span>
              <span class="class-txt" @click="toworkList(3)"> 微信表情包</span>

              <span class="class-txt" @click="toworkList(1)">
                自媒体内容运营</span
              >
            </div>
          </div>
          <div style="margin: 20px 0">
            <div class="class-tit">产品设计</div>
            <div class="class-txt" @click="toworkList(6)">
              产品设计企划 文创礼盒设计 产品包装设计 周边产品制作
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="show"
      position="right"
      :style="{ width: '30%', height: '100%' }"
    >
      <div class="" style="width: 100%; height: auto" @click="toHome">
        <img
          src="../assets/logo.png"
          alt=""
          style="width: 100%; height: 100%; margin: 10px auto"
        />
      </div>
      <van-sidebar
        v-model="activeKey"
        :style="{ width: '100%' }"
        @change="onChange"
      >
        <van-sidebar-item :title="systemInfo.navigation_four" to="/MbHome" />
        <van-sidebar-item title="案例" to="/MbworkList" />
        <van-sidebar-item
          :title="systemInfo.navigation_two"
          to="/MbServiceBusiness"
        />
        <van-sidebar-item
          :title="systemInfo.navigation_three"
          to="/MbIntroduction"
        />
        <van-sidebar-item
          :title="systemInfo.navigation_five"
          to="/MbContactUs"
        />
      </van-sidebar>
    </van-popup>
    <van-dialog v-model="qcshow" :title="qctitle" show-cancel-button>
      <img src="../assets/douyinqc.png" v-if="douyinshow" />
      <img src="../assets/wechatqc.jpg" v-if="wechatshow"  />
      <img src="../assets/redbookqc.png" v-if="redbookshow" />
      <img src="../assets/zhankuqc.png" v-if="zhankushow" />
    </van-dialog>
    <MbcontactNow/>
  </div>



 

</template>

<script>
// import MbHome from "./MbHome.vue";
import MbcontactNow from "./MbcontactNow.vue"


export default {
  components:{
    // MbHome,
    MbcontactNow
  },
  data() {
    return {
      show: false,
      activeKey: 0,
      systemInfo: {},
      isRouterActive: true,
      qcshow: false,
      douyinshow: false,
      wechatshow: false,
      redbookshow: false,
      zhankushow: false,
      // showMbHome:true,
      qctitle:""
    };
  },
  methods: {
    myqcshow(type) {
      this.qcshow=true
      if (type == 1) {
        this.qctitle="请扫描下方二维码关注我们站酷"
        this.zhankushow=true,
        this.redbookshow=false,
        this.wechatshow=false,
        this.douyinshow=false
      } else if (type == 2) {
                this.qctitle="请扫描下方二维码关注我们抖音"

         this.zhankushow=false,
        this.redbookshow=false,
        this.wechatshow=false,
        this.douyinshow=true
      } else if (type == 3) {
                this.qctitle="请扫描下方二维码关注我们小红书"

         this.zhankushow=false,
        this.redbookshow=true,
        this.wechatshow=false,
        this.douyinshow=false
      } else if (type == 4) {
                this.qctitle="请扫描下方二维码关注我们公众号"

         this.zhankushow=false,
        this.redbookshow=false,
        this.wechatshow=true,
        this.douyinshow=false
      }
    },
    refresh() {
      // location.reload();
      this.isRouterActive = false;
      this.$nextTick(() => {
        this.isRouterActive = true;
      });
      document.documentElement.scrollTop = 0;
    },
    // 展示右侧弹出弹出层
    showPopup() {
      this.show = true;
    },

    async getSystemList() {
      let res = await this.$API.system.getSystemList();
      if (res.code == 200) {
        this.systemInfo = res.data;
      }
    },
    onChange(index) {
      console.log("侧边导航栏change事件", index);
      // this.showMbHome=false;
      // this.isRouterActive=true;
      this.activeKey = index;
      this.show = false;
    },
    toHome() {
      if (this.activeKey == 0) {
        console.log("在首页");
        document.documentElement.scrollTop = 0;
      } else {
        console.log("不在首页");
        this.$router.push("/MbHome");
      }
    },

    toworkList(type) {
      if (this.activeKey == 1) {
        console.log("在案例列表页");
        this.$router.push({ name: "MbworkList", query: { id: type } });
        //重载页面
        this.refresh();
      } else {
        console.log("不在案列列表页");
        this.$router.push({ name: "MbworkList", query: { id: type } });
        this.activeKey = 1;
      }
    },
  },
  mounted() {
    this.getSystemList();
  },
};
</script>

<style scoped>
.class-box {
}
.class-tit {
  text-align: left;
  font-size: 11px;
  font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #ffffff;
  line-height: 29px;
}
.class-txt-box {
  display: flex;
  flex-wrap: wrap;
}
.class-txt {
  margin: 0 10px 0 0;
  text-align: left;
  font-size: 9px;
  font-family: SourceHanSansCN-Normal;
  font-weight: Normal;
  color: #ffffff;
  line-height: 25px;
}
.icon {
  width: 32px;
  height: 32px;
  /* background: #343434; */
  margin: 0 20px 0 0;
}
.icon-box {
  display: flex;
  margin: 20px 0;
}
.tipe {
  text-align: left;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.footer-logo {
  width: 94.5px;
  height: 48.5px;
  margin: 20px 0;
  box-sizing: border-box;
  padding: 20px 0 0 0;
}
.header {
  width: 100%;
  height: 65px;
  background: #000000;
  padding: 17px 20px;
  box-sizing: border-box;
}

.logo-box {
  width: 94.5px;
  height: 28.5px;
}
.nav-btn-box {
  width: 20.5px;
  height: 100%;
  margin: auto 0;
}
.footer {
  width: 100%;
  height: 520.5px;
  background: #000000;
}
</style>