<template>
  <div style="margin-top: 108px">
    <!-- banner -->
    <!-- <div >
        <Banner/>
    </div> -->
    <!-- about us -->
    <div class="content-box">
      <div class="content2-left">
        <div class="left-txt">ABOUT US</div>
      </div>
      <div class="content2-right">
        <div>
          <div class="introductiontxt" style="font-weight: bold">
            南京创心天降文化创意有限公司
          </div>
          <div
            class="introductiontxt"
            style="
              margin: 10px 0 30px;
              padding: 0 122px 0 0;
              box-sizing: border-box;
            "
          >
            天降文化”成立于2015年7月，品牌名称源自“故天将降大任于斯人也”。公司以创造性的视觉创作为出发点，以全方位的视角进行设计实践，致力于打造一间有温度的视觉艺术工作室。
          </div>
          <div
            class="introductiontxt"
            style="
              margin: 10px 0 30px;
              padding: 0 122px 0 0;
              box-sizing: border-box;
            "
          >
            服务范畴为IP吉祥物全案、插画/漫画/绘本、表情/动画/视频、文创礼盒/产品开发、品牌/策划咨询、VI/空间全案等。
          </div>
          <div
            class="introductiontxt"
            style="
              margin: 10px 0 30px;
              padding: 0 122px 40px 0;
              box-sizing: border-box;
            "
          >
            在视觉日益同质化的互联网时代，“天降文化”笃信创意的核心价值，是不易被公式化的设计所淹没的。我们将不断修正和精进每一个服务流程，为您的品牌提升，量身打造最优质的创意方案！
          </div>
        </div>
      </div>
    </div>
    <!-- 即刻联系 -->
    <div style="width: 80%; margin: 0 auto">
      <!-- 即刻联系 -->
      <ContactNow />
      <!-- 图片 -->
      <div style="width: 1320px; height: 886px; margin: 0 auto 100px">
        <img
          alt=""
          src="../assets/photo.png"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div style="width: 80%; margin: 0 auto">
      <!-- 顶部标题 -->
      <div style="margin-top: 50px">
        <div class="title">最新动态</div>
        <div class="subtitle">
          <div class="txt">NEWS</div>
          <div class="line" style="width: 80px"></div>
        </div>
      </div>
      <!-- 案列列表   -->
      <div class="award-list" style="margin: 50px 0 0 0">
        <div
          class="award-item"
          v-for="(item, index) in newsList.slice(0, 3)"
          :key="index"
          @click="toNewsDetail(item.id)"
        >
          <div class="item-img">
            <img
              :src="item.cover"
              alt=""
              style="width: 100%; height: 100%"
              class="fangda"
            />
          </div>
          <div class="mask"></div>

          <!-- 获奖项目 -->
          <div style="padding: 20px 0">
            <div class="award-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <!-- more -->
      <div>
        <div class="more-btn" style="cursor: pointer">
          <img src="../assets/more.png" alt="" @click="toNewsList" />
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="partner-box">
      <div class="partner-tit">
        <div class="tit1">合作伙伴</div>
        <div class="tit2">COOPERATIVE PARTNER</div>
      </div>
      <div class="partner-list">
        <div class="partner-item" v-for="index in 12" :key="index">
          <img
            alt=""
            :src="require('@/assets/logoList/logo' + index + '.png')"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div>
    <!-- 即刻联系 -->
    <ContactNow />
    <div
      style="height: 40px; background-color: #ffffff; margin-top: 40px"
    ></div>
  </div>
</template>

<script>
import ContactNow from "./ContactNow.vue";
import Banner from "./banner.vue";
export default {
  name: "Introduction",
  components: {
    ContactNow,
    Banner,
  },
  data() {
    return {
      newsList: [],
    };
  },
  mounted() {
    this.toTop();
    // this.getnewsListByClass({ id: 8 });
    this.getNewsList();
  },
  methods: {
    toNewsList() {
      this.$router.push("/NewsList");
    },
    toNewsDetail(id) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "NewsDetail", query: { id: id } });
    },

    async getNewsList() {
      let res = await this.$API.news.getNewsList();
      if (res.code == 200) {
        this.newsList = res.data;
        console.log("最新动态", this.newsList);
      }
    },
    toworkList() {
      this.$router.push("/WorkList");
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script >

<style scoped>
.title {
  text-align: left;
  height: 48px;
  font-size: 32px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
  line-height: 48px;
}
.subtitle {
  position: relative;
  width: 120px;
  height: 31px;
}
.award-title {
  max-width: 411px;
  max-height: 136px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  text-align: left;
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 36px;
}
.txt {
  text-align: left;
  z-index: 9;
  position: absolute;
  top: -18px;
  width: 100%;
  height: 31px;
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 68px;
}
.line {
  position: absolute;
  bottom: 0;
  text-align: left;

  height: 10px;
  background: #ff8c26;
}
.award-list {
  width: 80%;
  margin: 0 auto;
  height: 400px;
  display: flex;
}
.award-item {
  position: relative;
  cursor: pointer;
  margin: 0 47.828px;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 411px;
  height: 291px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.award-item:hover .mask {
  cursor: pointer;
  display: block;
}
.award-item:hover .fangda {
  transform: scale(1.2);
  cursor: pointer;
}
.item-img {
  width: 411px;
  height: 291px;
  overflow: hidden;
  /* display: none;
 transition: all 2s; */
  /* background-color: black; */
}
.fangda {
  transition: all 0.6s;
}
.fangda:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.content-box {
  box-sizing: border-box;
  width: 80%;
  margin: 150px auto 0;
  display: flex;
  justify-content: space-between;
}
.content2-left {
  width: 50%;
  align-items: right;
}
.left-txt {
  height: 23px;
  font-size: 30px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
  line-height: 68px;
}
.content2-right {
  width: 50%;
}
.introductiontxt {
  text-align: left;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: Normal;
  color: #212121;
  line-height: 30px;
}
.partner-box {
  margin: 100px 0 0 0;
}
.partner-tit {
}
.tit1 {
  height: 34px;
  font-size: 36px;
  font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #333333;
  line-height: 1px;
}
.tit2 {
  height: 19px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 400;
  color: #666666;
  line-height: 1px;
}
.partner-list {
  width: 80%;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partner-item {
  margin: 30px 0;
  width: 444px;
  height: 119px;
  background: #ffffff;
  /* border: 1px solid #A0A0A0; */
  border-radius: 58px;
}
.more-btn {
  margin: 100px 0;
}
</style>