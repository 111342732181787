<template>
  <div style="padding: 20px 0">
   <MbWorkListCom :ifIndex="0"/>
  </div>
</template>

<script>
import MbWorkListCom from "./MbWorkListCom.vue"
export default {
  name: "MbworkList",
  components:{
    MbWorkListCom
  }
 
 
};
</script>

<style scoped>

</style>