<template>
  <div style="margin: 20px 0">
    <div>
      <!-- 案例封面 -->
      <div class="cover-box" v-if="videoArr.length == 0">
        <img
          :src="workDetail.cover"
          alt=""
          style="width: calc(100% - 40px); margin: 0 auto"
        />
      </div>
      <!-- 案例长图 -->
      <div class="content-box">
        <div v-if="videoArr.length != 0">
          <video
            v-for="(item, index) in videoArr"
            :key="index"
            :src="item"
            type=""
            :autoplay="true"
            controls="controls"
            muted
            loop
            style="width: calc(100% - 40px); height: auto; margin: 0 auto"
          ></video>
        </div>

        <img
          :src="item"
          alt=""
          v-for="(item, index) in imgArr"
          :key="index"
          style="width: calc(100% - 40px); height: auto; margin: 0 auto"
        />
      </div>
    </div>

    <div style="width: calc(100% - 67px); margin: 0 auto">
      <div>
        <div class="txt1">点击图标，联系我们</div>
        <div class="btn-box">
          <div class="btn-item" @click="callphone">
            <img
              src="../assets/myphone.png"
              alt=""
              style="width: 100%; height: 100%;"
            />
          </div>
          <div class="btn-item" @click="myqcshow">
            <img
              src="../assets/mywechat.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
      <div class="myline"></div>

      <h4>相关案例</h4>
      <!-- 相关案例 -->
      <!-- <div >点击图标，联系我们</div> -->
      <div class="news-list">
        <div
          class="news-item"
          v-for="(item, index) in newsList"
          :key="index"
          @click="frashWorks(item)"
        >
          <div class="news-top">
            <div class="news-img">
              <img :src="item.cover" style="width: 100%; height: 100%" alt="" />
            </div>
          </div>

          <div class="news-bottom">
            <div class="news-tit">{{ item.title }}</div>
            <!-- <div class="news-content">
                <div class="news-time">{{ item.subtitle }}</div>
                <div class="news-scord">
                  <div
                    style="width: 9px; height: 9px"
                    v-for="star in 5"
                    :key="star"
                  >
                    <img
                      src="../assets/star.png"
                      style="width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="more" @click="toWorkList">
        <img
          src="../assets/more.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <van-dialog v-model="qcshow" title="扫一扫 咨询设计总监" show-cancel-button>
      <img src="../assets/qrcode.png" style="width:280px;height:280px;" />
    </van-dialog>
    <van-popup v-model="phoneshow" position="bottom" :style="{ height: '20%' ,backgroundColor:'#ffffff00'}" >
      <div @click="callphone" class="popup-btn"><i class="el-icon-phone"></i>呼叫159 5188 9491</div>
      <div @click="mycallclose" class="popup-btn">取消</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "MbworkDetail",
  components: {},
  mounted() {
    console.log("路由参数", this.$route.query.id, this.$route.query.workclass);
    this.id = this.$route.query.id;
    this.workClass = this.$route.query.workclass;
    this.getworkinfoByid();
    this.toTop();
    this.matchType();
  },
  data() {
    return {
      workDetail: {},
      imgArr: [],
      videoArr: [],
      id: 0,
      workClass: 0,
      newsList: [],
      qcshow:false,
      phoneshow:false,
    };
  },
  methods: {
    matchType() {
      this.imgArr = [];
      this.videoArr = [];
      console.log("方法执行");
      this.workDetail.content.forEach((item) => {
        let suffix = "";
        let fileArr = item.split(".");
        suffix = fileArr[fileArr.length - 1];
        let imgList = ["jpg", "jpeg", "png", "gif", "bmp"];
        imgList.some((it) => {
          if (it == suffix) {
            this.imgArr.push(item);
          }
        });
        let videoList = ["mp4", "m2v", "mkv", "ofd"];
        videoList.some((it) => {
          if (it == suffix) {
            this.videoArr.push(item);
          }
        });
      });
      console.log("图片", this.imgArr, "视频", this.videoArr);
    },

    toTop() {
      document.documentElement.scrollTop = 0;
    },
    toWorkList() {
      this.$router.push("/MbworkList");
    },

    async getworkinfoByid() {
      let res = await this.$API.works.getworkinfoByid(this.id);
      if (res.code == 200) {
        this.workDetail = res.data;
        this.newsList = res.data.xiangguananli;

        this.matchType();
        console.log(this.newsList);
      } else {
      }
      //  if(res=200){

      //  }
    },
    async frashWorks(item) {
      let res = await this.$API.works.getworkinfoByid(item.id);
      if (res.code == 200) {
        this.workDetail = res.data;

        this.matchType();
        console.log(this.workDetail);
        this.newsList = res.data.xiangguananli;

        this.showWorkDetail = false;
        this.$nextTick(() => {
          this.showWorkDetail = true;
          console.log("已经刷新");
          this.toTop();
        });
      } else {
      }
    },
    myqcshow(){
      this.qcshow=true;
    },
    mycallshow(){
      this.phoneshow=true;
    },
    callphone(){
       window.location.href = "tel:" + 15951889491;
    },
    mycallclose(){
      this.phoneshow=false;
    }
  },
};
// this.$route.query.id
</script>

<style scoped>
.popup-btn{
  background-color: #ffffff;
  margin:20px;
  padding:10px;
  font-family: Source Han Sans CN;
  font-size: 16px;
  border-radius: 20px;

}
.txt1 {
  margin-top:20px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  color: #6D6D6D;
}
.btn-box {
  display: flex;
  justify-content: center;
}
.btn-item {
  width: 32px;
  height: 32px;
  margin: 20px;
  /* background-color: #d6d6d6; */
  /* border-radius: 50%; */
  align-content: center;
}
h4 {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  margin:30px 0;
}
h4:before,
h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #6d6d6d;
  margin: auto 20px;
}
.myline {
  height: 10px;
  border-bottom: 1px dashed #d6d6d6;
}
.content-box {
  /* display: flex;
  flex-wrap: wrap; */
}
.news-list {
}
.news-item {
  width: calc(100% - 20px);
  margin: 10px;
}
.works-img {
  width: 100%;
  height: 85px;
  overflow: hidden;
}

.news-tit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  margin: 20px 0 50px 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #202016;
}
.works-time {
  /* margin:10px 0; */
  box-sizing: border-box;
  text-align: left;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}
.works-scord {
  display: flex;
  height: 9px;
  line-height: 9px;
}
.works-top {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.works-bottom {
}
.works-content {
  display: flex;
  justify-content: space-between;
}
.more {
  width: calc(100% - 208px);
  margin: 20px auto;
}
</style>