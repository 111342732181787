<template>
  <div>
    <div>
      <Mbbanner/>
      <!-- 案例鉴赏 -->
      <MbWorkListCom :ifIndex="1"  @call-back-father="refreshId"/>
      <!-- 更多按钮 -->
      <div class="more" @click="toworkList" @call-back-father="refreshId">
        <img src="../assets/more.png" alt="" style="width:100%;height:100%" >
      </div>
      <partner/>

    </div>
  <!-- <MbContactNow/> -->
  </div>
</template>

<script>
import partner from "./partner.vue"
import Mbbanner from "./Mbbanner.vue"
import MbWorkListCom from "./MbWorkListCom.vue"


export default {
name:"MbHome",
 components:{
    partner,
    Mbbanner,
    MbWorkListCom,
    // MbContactNow
  },
  data(){
    return{
    currId:1

    }
  },
  methods:{
    refreshId(value){
     this.currId=value;
    console.log("当前的类别", this.currId)


    },
    toworkList(){
    this.$router.push({name:"MbworkList",query : {id:this.currId}});
    },
  }
}
</script>

<style scoped>
.more{
  width:calc(100% - 275px);
  margin:0 auto;
}

</style>