if (!global._babelPolyfill) {
	require('babel-polyfill');
}
// import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()
// 引入Vue
import Vue from 'vue'
import "@/font/font.css"
// 引入APP
import App from './App.vue'
// 引入VueRouter
import VueRouter from 'vue-router';
// 引入ElementUI组件库
import ElementUI from 'element-ui';
// 引入ElementUI全部样式
import 'element-ui/lib/theme-chalk/index.css';
//引入路由器
import router from './router';
import WechatTitle from 'vue-wechat-title'
import directive from './change';
import 'amfe-flexible';

import { Popup } from 'vant';
Vue.use(Popup);

import { Sidebar, SidebarItem } from 'vant';
Vue.use(Sidebar);
Vue.use(SidebarItem);

import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe).use(SwipeItem);

import { Tab, Tabs } from 'vant';

Vue.use(Tab).use(Tabs);
import { Dialog } from 'vant';


// 全局注册
Vue.use(Dialog);
// 引入API相关的请求接口
import API from "@/api";
// 组件实例的原型的原型指向的Vue,prototype ,任意组件可以使用API相关接口
Vue.prototype.$API = API
// 关闭Vue生产提示
Vue.config.productionTip = false
// 应用elementUI
Vue.use(ElementUI);
// 应用VueRouter
Vue.use(VueRouter)
Vue.use(WechatTitle)

// 注册名为 change 的指令
Vue.directive("change", directive);
new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')

