<template>
  <div style="margin-top: 28px">
    <div style="margin:0 0 22px 0">
      <div style=" width: calc(100% - 20px);height:63px; margin:0 auto">
         <div style="" class="myh2">
          服务企业超<span v-change="" style="" class="myh1">{{systemInfo.service_num}} </span> <span class="myh1">+</span>
        </div>
         <div style="" class="myh2">创意遍及北上广深</div>
      </div>
      <div class="photo-box">
        <img
          src="../assets/photo.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
      <div style=" width: calc(100% - 20px);height:63px;display: flex; margin:0 auto">
        <div style="width:33.3%;">
          <div class="myh3"><span v-change="" style="" class="myh3"> {{systemInfo.original_num}}</span>+</div>
          <div class="myh4">原创案例</div>
        </div>
        <div style="width:33.3%; border-left: 1px solid #999999;border-right: 1px solid #999999">
          <div class="myh3"><span v-change="" style="" class="myh3"> {{systemInfo.experience}}</span>年+</div>
          <div class="myh4">经验沉淀</div>
        </div>
        <div style="width:33.3%;">
          <div class="myh3"><span v-change="" style="" class="myh3"> 1</span>站式</div>
          <div class="myh4">品牌视觉服务</div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div>
      <div class="partner-tit">合作伙伴</div>
      <div class="partner-txt">COOPERATIVE PARTNER</div>
    </div>
    <div class="partner-list">
      <div class="partner-item" v-for="index in 12" :key="index">
        <img
          alt=""
          :src="require('@/assets/logoList/logo' + index + '.png')"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partner",
  data(){
    return{
        systemInfo:{}
    }
  },
  mounted() {
    this.getSystemList()
  },
  methods:{
      async getSystemList() {
      let res = await this.$API.system.getSystemList();
      if (res.code == 200) {
        this.systemInfo=res.data;
      
      }
    },
  }
};

</script>

<style scoped>
.myh1{
font-size: 50px;
font-family: SourceHanSansCN-Regular;
font-weight: bold;
color:  #FF8C26;
}
.myh2{
font-size: 18.5px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
}
.myh3{
font-size: 27px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FF8C26;
}
.myh4{
font-size: 11px;
font-family: SourceHanSansCN-Medium;
font-weight: Medium;
color: #333333;
}
.photo-box {

  width: calc(100% - 20px);
  margin: 20px auto;
}
.partner-tit {
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.partner-txt {
  font-size: 7px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
.partner-list {
  width: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.partner-item {
  width: calc(50% - 20px);
  height: 42px;
  border-radius: 30%;
  margin: 10px;
}
</style>