// js实现动态变化的数字
const directive = {
    inserted(el, binding) {
      setTimeout(() =>{
        // 获取到需要动态变化的数值
      let finalNum = el.innerText;
      console.log("最大值" ,binding.value)
      let count = 0;
      if(finalNum < 1000){
        let timer = setInterval(() => {
          count++;
          el.innerText = count;
          if (count > finalNum) {
            //  避免count大于finalNum最终数字显示不对
            count = finalNum;
            el.innerText = count;
            // 清空定时器
            clearInterval(timer);
            timer = null;
          }
        }, 1);
      }else{
        let timer = setInterval(() => {
          count=count+2;
          el.innerText = count;
          if (count > finalNum) {
            //  避免count大于finalNum最终数字显示不对
            count = finalNum;
            el.innerText = count;
            // 清空定时器
            clearInterval(timer);
            timer = null;
          }
        }, 1);
      }
     
        console.log('延迟执行');
        }, 3000);
      
    },
  };
  export default directive;