<template>
  <div style="margin-top:110px">
    <div class="content-box">
      <div class="content1-left">
        <div class="left-txt">CONTACT US</div>
      </div>
      <div class="content1-right">
        <div>

          <div class="contacttxt1" style="margin-bottom:30px; padding:0 150px 0 0;box-sizing:border-box;">很荣幸，因为您选择了天降文化作为您的合作伙伴。我们相信，您的选择是对我们专业能力和服务质量的最好肯定。</div>
          <div class="contacttxt1" style="margin-bottom:30px; padding:0 150px 0 0;box-sizing:border-box;" >在天降文化，我们注重的是品质和诚信，致力于为客户提供最优质的服务。我们的行事方法是简单而又直接的，我们会全力履行我们的服务承诺，并且始终以客户为中心。</div>
          <div class="contacttxt1" style="margin-bottom:30px; padding:0 150px 0 0;box-sizing:border-box;">我们相信，通过彼此协助和信任，我们能够实现共同的目标。如果您有任何问题或疑虑，请随时与我们联系。我们期待与您合作，共同创造更加美好的未来！</div>
        </div>
        <div style="margin:114px 0 20px 0">
          <div class="txt2">天降文化</div>
          <div class="txt2">动漫IP品牌设计</div>
        </div>
         <div>
          <div class="contacttxt1">地址：江苏省南京市玄武区太平北路136-5、202-205室 </div>
          <div class="contacttxt1">商务咨询：159 5188 9491 （杨经理）</div>
          <div class="contacttxt1">商务咨询：187 2400 7312 （吕经理）</div>
          <div class="contacttxt1" style="margin:20px 0">邮箱：674141147@qq.com</div>
        </div>
        <div style="width: 182px; border: dashed 1px #828282;">
          <img alt="" src="../assets/qrcode.png" style="width:100%; height:100%">
          
        </div>
        <div style="width: 182px;margin-top:20px" >
          <div class="txt3">微信扫一扫</div>
          <div class="txt3">详细咨询沟通</div>
        </div>

      </div>
    </div>
     <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
    <ContactNow/>
   <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
    
    




  </div>
</template>
<script>
import ContactNow  from './ContactNow.vue'

export  default {
name:'ContactUs',
components:{
  ContactNow
},
mounted(){
  this.toTop()
},
methods: {
    toTop() {

      document.documentElement.scrollTop = 0;

    },
},
}
</script>

<style scoped>
.content-box{
box-sizing:border-box;
width:1520px;
margin: 150px auto 0;
display:flex;
justify-content: space-between;
}
.content1-left{
width: 50%;
align-items: right;
}
.left-txt{
height: 23px;
font-size: 30px;
font-family: SourceHanSansCN-Bold;
font-weight: bold;
color: #212121;
line-height: 68px;
}
.content1-right{
 width: 50%;
 
}
.contacttxt1{
text-align: left;  
font-size: 22px;
font-family: SourceHanSansCN-Normal;
font-weight: 400;
color: #212121;
line-height: 36px;
}
.txt2{
text-align: left;  
font-size: 34px;
font-family: SourceHanSansCN-Bold;
font-weight: bold;
color: #212121;
line-height: 48px;
}
.txt3{
text-align: center;
font-size: 20px;
font-family: SourceHanSansCN-Normal;
font-weight: 400;
color: #212121;
line-height: 25px;
}

</style>