<template>
  <div style="margin-top: 160px">
    <!-- <div>
      <Banner />
    </div> -->
    <!-- <ContactNow /> -->
    <!-- 案例 -->
    <div style="width: 1520px; margin: 20px auto 0">
      <!-- 顶部标题 -->
      <div style="margin: 60px 0 30px">
        <!-- <div class="title">案例</div> -->
        <div class="subtitle">
          <div class="title" style="z-index: 9; position: absolute; top: -10px">
            案例
          </div>
          <div class="line" style="width: 80px"></div>
        </div>
      </div>
      <!-- 案列列表   -->
      <div class="works-box">
        <div class="left-nav">
          <div class="nav-list">
            <div
              class="nav-item"
              v-for="(item, index) in navList.slice(0, 7)"
              :key="index"
              @click="showWorksById(item.id, index)"
            >
              <div
                class="item-id"
                :class="active == index ? 'active-item-id' : ''"
              >
                0{{ item.id }}
              </div>
              <div
                class="item-name"
                :class="active == index ? 'active-item-name' : ''"
              >
                {{ item.class_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-card" v-if="worksBoxShow">
          <div class="left-part">
            <div class="first-card">
              <div
                class="first-card"
                @click="toworkDetail(topList[0].id)"
                v-if="showOne"
              >
                <div class="first-img">
                  <img
                    :src="topList[0].show_top_img"
                    alt=""
                    style="width: 100%; height: 100%"
                    class="fangda"
                  />
                </div>

                <div class="first-des">
                  <div class="subtitle1">
                    <div class="txt1">{{ topList[0].title }}</div>
                    <div class="line1"></div>
                  </div>
                  <div class="text1">{{ topList[0].work_class_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-part">
            <div class="top-part">
              <div class="second-card">
                <div
                  class="second-card"
                  @click="toworkDetail(topList[1].id)"
                  v-if="showTwo"
                >
                  <div class="second-img">
                    <img
                      :src="topList[1].show_top_img"
                      alt=""
                      style="width: 100%; height: 100%"
                      class="fangda"
                    />
                  </div>
                  <div class="second-des">
                    <div class="subtitle1">
                      <div class="txt1">{{ topList[1].title }}</div>
                      <div class="line1"></div>
                    </div>
                    <div class="text1">{{ topList[1].work_class_name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <div class="third-card">
                <div
                  class="third-card"
                  @click="toworkDetail(topList[2].id)"
                  v-if="showThree"
                >
                  <div class="third-img">
                    <img
                      :src="topList[2].show_top_img"
                      alt=""
                      style="width: 100%; height: 100%"
                      class="fangda"
                    />
                  </div>
                  <div class="third-des">
                    <div class="subtitle1">
                      <div class="txt1">{{ topList[2].title }}</div>
                      <div class="line1"></div>
                    </div>
                    <div class="text1">{{ topList[2].work_class_name }}</div>
                  </div>
                </div>
              </div>
              <div class="look-more" @click="toworkList()">
                <div class="btn-icon" >
                  <img
                    src="../assets/btn-icon1.png"
                    alt=""
                                        class="fangda"

                    style="width: 100%; height: 100%"
                  />
                </div>
                <div class="btn-txt">查看更多</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 即刻联系-->
    <div
      style="height: 40px; background-color: #ffffff; margin-top: 40px"
    ></div>
    <ContactNow />
    <div
      style="height: 40px; background-color: #ffffff; margin-top: 40px"
    ></div>
  </div>
</template>

<script>
import Banner from "./banner.vue";
import ContactNow from "./ContactNow.vue";

export default {
  name: "IndustryCase",
  components: {
    Banner,
    ContactNow,
  },
  data() {
    return {
      // 当前按钮指标
      active: 0,
      currentWorksId: 1,
      showOne: false,
      showTwo: false,
      showThree: false,
      worksBoxShow: true,
      newsList: [],
      worksList: [],
      topList: [],
      navList: [],
    };
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    // 根据导航展示案例
    async showWorksById(id, index) {
      this.active = index;
      this.currentWorksId = id;
      let res = await this.$API.works.getWorkShowTop(this.currentWorksId);

      if (res.code == 200) {
        this.topList = res.data;
        console.log(this.topList.length);
        if (this.topList.length == 0) {
          this.showOne = false;
          this.showTwo = false;
          this.showThree = false;
        }
        if (this.topList.length >= 1) {
          this.showOne = true;
          this.showTwo = false;
          this.showThree = false;
          console.log("kapian1", this.showOne);
        }
        if (this.topList.length >= 2) {
          this.showOne = true;
          this.showTwo = true;
          this.showThree = false;
          console.log("kapian2", this.showTwo);
        }
        if (this.topList.length >= 3) {
          this.showOne = true;
          this.showTwo = true;
          this.showThree = true;
          console.log("kapian3", this.showThree);
        }
        console.log("置顶作品数组", this.topList);

        this.worksBoxShow = false;
        this.$nextTick(() => {
          this.worksBoxShow = true;
        });
      }
    },
    async getWorkShowTop() {
      let res = await this.$API.works.getWorkShowTop(this.currentWorksId);
      if (res.code == 200) {
        this.topList = res.data;
        console.log(this.topList.length);
        if (this.topList.length == 0) {
          this.showOne = false;
          this.showTwo = false;
          this.showThree = false;
        }
        if (this.topList.length >= 1) {
          this.showOne = true;
          this.showTwo = false;
          this.showThree = false;
          console.log("kapian1", this.showOne);
        }
        if (this.topList.length >= 2) {
          this.showOne = true;
          this.showTwo = true;
          this.showThree = false;
          console.log("kapian2", this.showTwo);
        }
        if (this.topList.length >= 3) {
          this.showOne = true;
          this.showTwo = true;
          this.showThree = true;
          console.log("kapian3", this.showThree);
        }
      }
    },
    async getworkListByClass(item) {
      let res = await this.$API.works.getworkListByClass(item);
      if (res.code == 200) {
        this.worksList = res.data;
      }
    },
    async getnewsListByClass(item) {
      let res = await this.$API.works.getworkListByClass(item);
      if (res.code == 200) {
        this.newsList = res.data;
        console.log("最新动态", this.newsList);
      }
    },
    toworkDetail(id) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "WorkDetail", query: { id: id } });
    },
    // goWorkList(id,classname){
    //    this.$router.push({name:'WorkList',query: {id:id,classname:classname}})

    // },
    toworkList() {
      this.$router.push({
        name: "WorkList",
        query: { id: this.currentWorksId },
      });
    },
    //加载作品分类导航

    async getWorkClassList() {
      let res = await this.$API.works.getWorkClassList();
      if (res.code == 200) {
        this.navList = res.data;
      }
    },
    async getWorksList() {
      let res = await this.$API.works.getWorksList();
      if (res.code == 200) {
        this.worksList = res.data;
      }
    },
  },
  mounted() {
    //加载作品分类导航
    this.getWorkClassList();
    this.getWorkShowTop();

    // this.getWorksList()
    this.getnewsListByClass({ id: 8 });
    this.toTop();
  },
};
</script>

<style scoped>
.left-part {
  width: 50%;
  margin: 0 40px 0 0;
}
.first-card {
  width: 580px;
  min-height: 720px;
  box-shadow: 0px 10px 10px 0px rgba(255, 140, 38, 0.3);
}
.second-card {
  width: 580px;
  min-height: 341px;
  box-shadow: 0px 10px 10px 0px rgba(255, 140, 38, 0.3);
}
.third-card {
  width: 443px;
  box-shadow: 0px 10px 10px 0px rgba(255, 140, 38, 0.3);
}
/* .first-card {
  width: 580px;
  box-shadow: 0px 10px 10px 0px rgba(255, 140, 38, 0.3);
} */
.first-img {
  width: 580px;
  height: 570px;
  overflow: hidden;
}
.second-img {
  width: 580px;
  height: 241px;
  overflow: hidden;
}
.third-img {
  width: 443px;
  height: 241px;
  overflow: hidden;
}
.first-des {
  height: 150px;
  box-sizing: border-box;
  padding: 20px;
}
.second-des {
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
}
.third-des {
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
}
.text1 {
  text-align: left;
  height: 19px;
  font-size: 20px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
}
.right-part {
  width: 50%;
}
.bottom-part {
  display: flex;
  margin: 40px 0 0 0;
}
.look-more {
  cursor: pointer;
  margin: 0 0 0 40px;
  width: 132px;
  height: 341px;
  box-sizing: border-box;
  padding: 85px 31px;
  box-shadow: 0px 10px 10px 0px rgba(255, 140, 38, 0.3);
}
.btn-icon {
  width: 70px;
  height: 70px;
}
.look-more:hover .fangda {
  transform: scale(1.2);
  cursor: pointer;
}
.btn-txt {
  margin-top:24px;
  width: 70px;
  height: 70px;
  font-size: 24px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #333333;
  line-height: 31px;
}
.works-box {
  display: flex;
}
.left-nav {
  width: 30%;
}
.nav-list {
}
.nav-item {
  padding: 20px 0;
  display: flex;
  width: 237px;
  cursor: pointer;
}
.active-class {
  font-weight: bold;
}
.item-id {
  width: 60px;
  height: 57px;
  font-size: 24px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #424242;
  line-height: 57px;
}
.active-item-id {
  width: 60px;
  height: 57px;
  background-image: url(../assets/active.png);
  font-size: 28px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #ffffff;
  line-height: 57px;
}
/* .nav-item:first-child .item-id{
cursor: pointer;
width:60px;
height:57px;
background-image: url(../assets/active.png);
font-size: 28px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 57px;
}
.nav-item:first-child .item-name{
text-align: left;  
height: 57px;
font-size: 24px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #424242;
line-height: 57px;
} */
.item-name {
  text-align: left;
  height: 57px;
  font-size: 20px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #424242;
  line-height: 57px;
}
.active-item-name {
  text-align: left;
  height: 57px;
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 57px;
}
.right-card {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.award-list {
  width: 80px;
  margin: 0 auto;
  height: 400px;
  display: flex;
  justify-content: space-between;
}
.award-item {
  position: relative;
  cursor: pointer;
  width: 20%;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 411px;
  height: 291px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.award-item:hover .mask {
  cursor: pointer;
  display: block;
}
.award-item:hover .fangda {
  transform: scale(1.2);
  cursor: pointer;
}
.item-img {
  width: 411px;
  height: 291px;
  overflow: hidden;
  /* display: none;
 transition: all 2s; */
  /* background-color: black; */
}
.fangda {
  transition: all 0.6s;
}
.fangda:hover {
  transform: scale(1.2);
  cursor: pointer;
}
/* .second-img:hover {
   transform: scale(1.2);
   cursor: pointer;
  
}
.third-img:hover {
   transform: scale(1.2);
   cursor: pointer;
  
} */

.title {
  text-align: left;
  height: 48px;
  font-size: 32px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #212121;
  line-height: 48px;
}
.subtitle {
  position: relative;
  width: 120px;
  height: 31px;
}
.subtitle2 {
  position: relative;
  width: 250px;
  height: 31px;
}
.txt {
  z-index: 9;
  position: absolute;
  top: -18px;
  height: 31px;
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 68px;
}
.line {
  position: absolute;
  bottom: 0;
  text-align: left;

  height: 10px;
  background: #ff8c26;
}
.subtitle1 {
  position: relative;
width: 300px;  height: 31px;
}
.txt1 {
  z-index: 9;
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  position: absolute;
  top: 0;
  height: 24px;
  font-size: 20px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #424242;
  line-height: 24px;
}
.line1 {
  position: absolute;
  bottom: 5px;
  text-align: left;
  width: 100%;
  height: 10px;
  background: #ff8c26;
  opacity: 0.5;
}
</style>