import request from '@/utils/request'
// 查看作品分类信息
export const getWorkClassList=()=>request({
    url:'/work_class_list',
    method:'get',
})
// 作品top3
export const getWorkShowTop=(workId)=>request({
    url:'/index_works_show_top',
    method:'get',
    params: { work_class:workId }

})
// 查看作品信息
// export const getWorksList=()=>request({
//     url:'/works_list',
//     method:'get',
// })
// 上传图片
export const getPhoto=(photo)=>request({
    url:'/upload_img',
    method:'post',
    data:photo
})

// 添加作品信息
export const addWork=(work)=>request({
    url:'/works_add',
    method:'post',
    data:work
})

// 修改作品信息
export const updateWork=(work)=>request({
    url:'/works_edit',
    method:'post',
    data:work
})

// 删除作品信息
export const deleteWork=(work)=>request({
    url:'/works_del',
    method:'post',
    data:{id:work.id}
})
//置顶作品信息
export const topWork=(work)=>request({
    url:'/works_top',
    method:'post',
    data:{id:work.id}
})
// 添加修改作品信息
export const addOrUpdateWork=(work)=>{
    if(work.id){
        return  request({url:'/works_edit',method:'post', data:work})
    }else{
        return  request({url:'/works_add',method:'post', data:work})

    }
}

//根据分类查询案例
export const getworkListByClass=(workclass,if_index)=>request({
    url:'/index_works_list',
    method:'get',
    params: { work_class:workclass.id,if_index:if_index }
})

//根据分类查询案例
export const getworkinfoByid=(workid)=>request({
    url:'/index_works_info',
    method:'get',
    params: { id:workid }
})




