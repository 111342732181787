<template>
  <div style="margin-top:108px" >
    <div >
        <Banner/>
    </div>
    <!-- 数字模块 -->
    <div style="width:1520px;margin:0 auto">
     <!-- part1 -->
      <div style="width:1520px;margin:0  auto 20px auto ;">
        <div style="" class="myh2">
          服务企业超<span v-change="" style="" class="myh1">{{systemInfo.service_num}} </span> <span class="myh1">+</span>
        </div>
         <div style="" class="myh2">创意遍及北上广深</div>
      </div>
     <!-- part2 -->
       <div style="width:1520px;margin:10px auto ; display: flex;">
           <div style="width:33.3%">
            <div class="myh3"><span v-change="" style="" class="myh3"> {{systemInfo.original_num}}</span>+</div>
            <div class="myh4">原创案例</div>
           </div>
           <div style="width:33.3%">
            <div class="myh3"><span v-change="" style="" class="myh3"> {{systemInfo.experience}}</span>年+</div>

            <div class="myh4">经验沉淀</div>
           </div>
           <div style="width:33.3%">
            <div class="myh3"><span v-change="" style="" class="myh3"> 1</span>站式</div>
            <div class="myh4">品牌视觉服务</div>
           </div>
       </div>
     <!-- part3 -->
        <div class="card-list">
           <div class="card-item" v-for="(item,index) in cardList" :key="index">
             <div class="card-img">
              <img :src=item.url alt="">
             </div>
             <div ><pre class="card-tit">{{item.tit}}</pre></div>
           </div>
        </div>
    </div>

    <!-- 即刻联系 -->
    <div style="margin:66px 0">
        <ContactNow/>
    </div>
    <!-- 案例鉴赏 -->
     <div class="works-box">
      <div class="box-top">
            <div>
              <div class="title">案例鉴赏</div>
              <div class="subtitle">
                <div class="txt"> SEE OUR WORKS</div>
                <div class="line"></div>
              </div>
            </div>
            <div>
            <el-menu
              :default-active="activeIndex2"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              text-color="##727272"
             >
                <el-menu-item  @click="getWorksList" index="1-1">ALL </el-menu-item>

                <el-menu-item v-for="(item,index) in navList.slice( 0,7)" :key="index" @click="getworkListByClass(item)" :index="index">{{item.class_name}}</el-menu-item>

            </el-menu>
            </div>
      </div>
      <div class="box-content">
          <div class="works-list">
              <div class="works-item" v-for="(item,index) in worksList" :key="index" @click="toworkDetail(item)">
                <div class="works-top" >
                <div class="works-img">
                  <img :src=item.cover style="width:100%;height:100%" alt="" class="fangda">
                </div>
                <div class="mask"></div>
                </div>
                
                <div class="works-bottom">
                  <div class="works-tit">{{item.title}}</div>
                  <div class="works-content" >
                    <div class="works-time">{{item.subtitle}}</div>
                    <div class="works-scord">
                         <div style="width:18px;height:18px" v-for="star in 5" :key="star">
                          <img src="../assets/star.png" style="width:100%;height:100%" alt="">
                         </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div class="box-bottom">
        <div class="more-btn" style=" cursor: pointer;" @click="toworkList">
          <img src="../assets/more.png" alt="" >
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner-box">
      <div class="partner-tit">
        <div class="tit1">合作伙伴</div>
        <div class="tit2">COOPERATIVE PARTNER</div>
      </div>
      <div class="partner-list" >
        <div class="partner-item" v-for="index in 12" :key="index">
           <img alt="" :src="require('@/assets/logoList/logo' + index + '.png')" style="width:100%; height:100%">  
        </div>
      </div>
    </div>
    <!-- 即刻联系 -->
    <ContactNow/>
    <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>
  </div>
</template>
<script>
import Banner from "./banner.vue"
import ContactNow from "./ContactNow.vue"



export default {
  name: 'Home',
  components: {
   Banner,
   ContactNow,
    
  },
   data() {
      return {
        if_index:1,
        activeIndex2:"1-1"      , 
        systemInfo:{},
       navList:[
         {
          name:'ALL'
        },
        {
          name:'IP吉祥物全案'
        },
        {
          name:'动画视频'
        },
        {
          name:'表情包设计'
        },
        {
          name:'长图条漫'
        },
        {
          name:'商插绘本'
        },
        {
          name:'文创礼盒'
        },
         {
          name:'平面设计'
        },
        {
          id:"07",
          name:'商业空间全案'
        },
      ],
      cardList:[
        {
          url:require("@/assets/card/card1.png"),
          tit:'多款设计方案\n多种设计风格'
        },
         {
          url:require("@/assets/card/card2.png"),
          tit:'坚持精品创意\n独创设计有保障'
        },
         {
          url:require("@/assets/card/card3.png"),
          tit:'以用户需求导向\n提升品牌竞争力'
        },
         {
          url:require("@/assets/card/card4.png"),
          tit:'一对一沟通\n总监全程跟进'
        },
      ],
      worksList:[
        {
          url:require("@/assets/main/main1.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:1
        },
         {
          url:require("@/assets/main/main2.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main3.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        }, {
          url:require("@/assets/main/main4.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:2
        }, {
          url:require("@/assets/main/main5.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:4
        }, {
          url:require("@/assets/main/main6.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:2
        }, {
          url:require("@/assets/main/main1.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:4
        }, {
          url:require("@/assets/main/main2.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        }, {
          url:require("@/assets/main/main3.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main4.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:1
        }, {
          url:require("@/assets/main/main5.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main6.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        },
      ],
      activeIndex2: '1-1',
      value:500,
      };
    },
    methods: {
        toTop() {

      document.documentElement.scrollTop = 0;

    },
      toworkDetail(item){
        //  this.$router.push('/WorkDetail')
         this.$router.push({name:'WorkDetail',query: {id:item.id,workclass:item.work_class}})

      },
      toworkList(){
         this.$router.push('/WorkList')
      },
    // 导航栏点击事件
    handleSelect(key, keyPath) {
      
    },
    async getSystemList() {
      let res = await this.$API.system.getSystemList();
      if (res.code == 200) {
        this.systemInfo=res.data;
      
      }
    },
    async getWorkClassList() {
      let res = await this.$API.works.getWorkClassList();
      if (res.code == 200) {
        this.navList=res.data;
        // this.navList=this.navList.push({id: 0, class_name: "ALL"})
      }
    },
    async getWorksList() {
      let res= await this.$API.works.getworkListByClass({id:0,},this.if_index)
      if (res.code == 200) {
        this.worksList=res.data;
      }
    },

    async getworkListByClass(item){
      let res= await this.$API.works.getworkListByClass(item,this.if_index)
      if (res.code == 200) {
        this.worksList=res.data;
      }
    },
    // newDate(t){
    //   let time=t;
    //   console.log("t",t)
    //   let Arr=t.split(" ")
    //   console.log("切割后",Arr[0])
    //   return Arr[0]
    // }
    },
    mounted(){
      // this.newDate("2023-07-13 19:58:23")
      this.getSystemList()
      this.getWorkClassList()
      this.getWorksList()
      this.toTop()
    }
 
}
</script>
<style scoped>
.el-menu-item{
  
  height:64px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu-item.is-active {
  font-size:18px;
  color: #000;
  border-bottom: 2px solid #FF8C26;
}
.myh1{
text-align: left;         
font-size: 172px;
font-family: SourceHanSansCN-Heavy;
font-weight: bolder;
color: #FF8C26;
}
.myh2{
text-align: center;       
font-size: 66px;
padding:69px auto;
font-family:   SourceHanSansCN-Heavy;
font-weight: 800;
color: #333333;
}
.myh3{
text-align: center; 
height: 120px;
font-size: 100px;
font-family: SourceHanSansCN-Bold;
font-weight: bold;
color: #FF8C26;
line-height: 120px;
}
.myh4{
text-align: center; 
height: 100px;
font-size: 40px;
font-family: SourceHanSansCN-Medium;
font-weight: 400;
color: #333333;
line-height: 100px;
}
.numgrow:hover{
  color: #FF8C26;
  text-orientation: upright;
}

.partner-box{
margin:100px 0;
}
.partner-tit{

}
.box-content{

}
.card-list{
width:1520px;
margin:0 auto; 
display: flex;
justify-content: space-between;
}
.card-item{
width:20%;
margin:10px 10px 0 10px;
padding:10px 10px 0 10px;
box-sizing: border-box;
}
.card-img{
width: 237px;
height: 237px;
}
.card-tit{
margin-top: 20px;  
width: 237px;
height: 85px;
font-size: 32px;
font-family:  SourceHanSansCN-Medium;
font-weight: 500;
color: #333333;
line-height: 54px;
}
.works-list{
width:1520px;
margin:20px auto; 
display: flex;
flex-wrap: wrap;
}
.works-item{
width: 311px;
margin:10px 30px;
/* padding:10px 0; */
box-sizing: border-box;
cursor: pointer;
}
.mask{
position:absolute;
top:10px;
left:10px;  
width: 311px;
height: 191px;
background-color: rgba(0, 0,0, 0.7);
display: none;
}
.works-top:hover .mask{
cursor: pointer;
display: block;
}
.fangda{
  transition: all 0.6s;
}
.works-top:hover .fangda{
transform: scale(1.2);
cursor: pointer;
}
.works-img{
width: 311px;
height: 191px;
overflow: hidden;
}

.works-tit{
overflow: hidden;
white-space: nowrap; /* 禁止换行 */
text-overflow: ellipsis; /* 超过一行时省略号代替 */  
margin:10px 0;
box-sizing: border-box;
text-align: left;
height: 26px;
font-size: 21px;
font-family:  SourceHanSansCN-Regular;
font-weight: 400;
color: #202016;
line-height: 26px;
}
.works-time{
/* margin:10px 0; */
box-sizing: border-box;
text-align: left;
height: 18px;
font-size: 14px;
font-family:  SourceHanSansCN-Regular;
font-weight: 400;
color: #727272;
line-height: 18px;
}
.works-scord{
display: flex;
height:18px;
line-height: 18px;
}
.works-top{
position:relative;  
padding:10px;
box-sizing: border-box;
}
.works-bottom{
padding:10px;
box-sizing: border-box;
}
.works-content{
display: flex;
justify-content: space-between;
width:311px;
}


.box-top{
  width:1520px;
  margin:20px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.title{
text-align: left;  
height: 37px;
font-size: 28px;
font-family: SourceHanSansCN-Bold;
font-weight: bold;
color: #212121;
line-height: 37px;

}
.subtitle{
position: relative;
width: 120px;
height: 31px;

}
.txt{
z-index: 9;
  position: absolute;
  top: -18px;
  height: 31px;
  font-size: 24px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  color: #424242;
  line-height: 68px;
  width:210px;

}
.line{
position:absolute;
bottom:0;
text-align: left;
width: 153px;
height: 12px;
background: #FF8C26;
  width:210px;

}
.tit1{
height: 34px;
font-size: 36px;
font-family:  SourceHanSansCN-Medium;
font-weight: 500;
color: #333333;
line-height: 1px;
}
.tit2{
height: 19px;
font-size: 24px;
font-family: PingFang;
font-weight: 400;
color: #666666;
line-height: 1px;
}
.partner-list{
width: 1520px;  
margin: 100px auto;
display:flex;
justify-content: space-between;
flex-wrap: wrap;
}
.partner-item{
margin: 30px 0;  
width: 444px;
height: 119px;
background: #FFFFFF;
/* border: 1px solid #A0A0A0; */
border-radius: 58px;
}
</style>


</style>