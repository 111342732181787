<template>
  <div style="  min-width:1920px;
">
        <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- 导航区 -->
    <div id="header" class="header">
      <div
        class="header-left"
        style="width: 189px; height: 57px; cursor: pointer"
        @click="toHome()"
      >
        <img
          alt=""
          src="../assets/logo-white.png"
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="header-left">
        <el-menu
          style="height: 10px"
          router
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#000000"
          text-color="#fff"
          active-text-color=" #ffd04b"
        >
          <el-menu-item index="/Home">{{
            systemInfo.navigation_four
          }}</el-menu-item>
          <el-menu-item index="/IndustryCase">{{
            systemInfo.navigation_one
          }}</el-menu-item>
          <el-menu-item index="/ServiceBusiness">{{
            systemInfo.navigation_two
          }}</el-menu-item>
          <el-menu-item index="/Introduction">{{
            systemInfo.navigation_three
          }}</el-menu-item>
          <el-menu-item index="/ContactUs">{{
            systemInfo.navigation_five
          }}     <span style="margin-left:50px">159-5188-9491</span></el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 展示区 -->
    <div id="content">
      <!-- <Home v-if="showHome"/> -->

      <router-view v-wechat-title="$route.meta.title" v-if="isRouterActive"></router-view>

    </div>
    <!-- 即刻联系 -->
    <!-- <div class="contact-box">
      <div class="contact-img">
        <img alt="" src="./assets/logo.png" style="width: 100%; height: 100%" />
      </div>
      <div class="contact-txt">
        天降文化是一家动漫IP品牌设计公司，我们以市场需求为导向，为企业提供一站式的创新解决方案。如需设计咨询，请联系我们专家团队！
      </div>
      <div class="contact-btn">
        <div class="contact-btn-txt">即刻联系</div>
        <div style="width: 27px; height: 12px; line-height: 62px">
          <img
            alt=""
            src="./assets/btn-icon.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div> -->
    <div class="footer">
      <div class="container">
        <div class="content-left">
          <!-- logo -->
          <!-- position: absolute;
              top: -50px;
              left: -15px; -->
          <div style="width: 189px; height: auto;cursor: pointer" @click="toHome" >
            <img
              alt=""
              src="../assets/footer-logo.png"
              style="width: 100%; height: 100%"
            />
          </div>
          <!-- tipe -->
          <div class="tipe">
            <div class="tipe-txt">南京市玄武区太平北路136-5，202-205室</div>
            <div class="tipe-txt">邮编：2100000</div>
            <div class="tipe-txt">手机：15951889491</div>
            <div class="tipe-txt">微信：15951889491</div>
          </div>
          <!-- position:absolute;
  top:236px;
  left: 10px; -->
          <div class="tipe-txt" style="">社交媒体：</div>

          <!-- icon -->
          <div class="icon">
            <div
              class="icon-box"
              style="
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #343434;
                margin: 0 10px 0 0;
              "
              @click="myqcshow(1)"
            >
              <img
                alt=""
                src="../assets/zhanku.png"
                style="width: 32px; height: 32px"
              />
            </div>
            <div
              class="icon-box"
              style="
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #343434;
                margin: 0 10px;
              "
                            @click="myqcshow(2)"

            >
              <img
                alt=""
                src="../assets/douyin.png"
                style="width: 32px; height: 32px"
              />
            </div>
            <div
              class="icon-box"
              style="
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #343434;
                margin: 0 10px;
              "
                            @click="myqcshow(3)"

            >
              <img
                alt=""
                src="../assets/redbook.png"
                style="width: 32px; height: 32px"
              />
            </div>
            <div
              class="icon-box"
              style="
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: #343434;
                margin: 0 10px;
              "
                            @click="myqcshow(4)"

            >
              <img
                alt=""
                src="../assets/weixin.png"
                style="width: 32px; height: 32px"
              />
            </div>
          </div>
        </div>
        <div class="content-center">
          <div
            class="center-item"
          >
            <div class="center-item-tit">IP形象服务</div>
            <div class="center-item-txt">
               <span @click="toworkList(1)" style="  cursor: pointer;
"> IP形象全案</span> 
                <span @click="toworkList(7)" style="  cursor: pointer;
"> VI规范设计 平面设计</span>
                 
                  <span @click="toworkList(0)" style="  cursor: pointer;
"> 商业空间设计</span>
                       </div>
          </div>
          <div
            class="center-item"
          >
            <div class="center-item-tit">品牌内容运营</div>
            <div class="center-item-txt">
              <span  @click="toworkList(2)" style="  cursor: pointer;
"> 宣传动画</span> 
              <span @click="toworkList(4)" style="  cursor: pointer;
"> 营销连载条漫</span>
              <span @click="toworkList(5)" style="  cursor: pointer;
"> IP插画绘本</span>
              <span @click="toworkList(2)" style="  cursor: pointer;
"> 创意短视频</span>
              <span @click="toworkList(3)" style="  cursor: pointer;
"> 微信表情包</span>
              <span @click="toworkList(0)" style="  cursor: pointer;
"> 自媒体内容运营</span>
              </div>
          </div>
          <div
            class="center-item"
          >
            <div class="center-item-tit">产品设计</div>
            <div class="center-item-txt" @click="toworkList(6)" style="  cursor: pointer;
">产品设计企划  文创礼盒设计  产品包装设计  周边产品制作</div>
          </div>
        </div>
        <div class="content-right">
          <!-- phone -->
          <div class="phone">
            <div class="phone-tit">联系我们</div>
            <div class="phone-num">15951889491</div>
          </div>
          <!-- pccode -->
          <div class="qccode">
            <img
              alt=""
              src="../assets/qrcode.png"
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="qctipe">天降文化设计咨询</div>
        </div>
      </div>
    </div>
    <el-dialog :title="qctitle" :visible.sync="qcshow">
      <img src="../assets/douyinqc.png" v-if="douyinshow" />
      <img src="../assets/wechatqc.jpg" v-if="wechatshow" />
      <img src="../assets/redbookqc.png" v-if="redbookshow" />
      <img src="../assets/zhankuqc.png" v-if="zhankushow" />
    </el-dialog>
  </div>
</template>

<script>
import ScaleBox from "vue2-scale-box";
// import Home from "./Home.vue";
export default {
    name:"PcIndex",
mounted() {

    this.getSystemList();
  },
  data() {
    return {
       screenWidth: 1920, //宽屏幕尺寸
      screeHeight: 1080,
      activeIndex: "/Home",
      currPage:"/Home",
      systemInfo: {},
      isRouterActive:true,
      qcshow: false,
      douyinshow: false,
      wechatshow: false,
      redbookshow: false,
      zhankushow: false,
      // showHome:true,
      qctitle:""
    };
  },
  methods: {
     myqcshow(type) {
      this.qcshow=true
      if (type == 1) {
        this.qctitle="请扫描下方二维码关注我们站酷"
        this.zhankushow=true,
        this.redbookshow=false,
        this.wechatshow=false,
        this.douyinshow=false
      } else if (type == 2) {
                this.qctitle="请扫描下方二维码关注我们抖音"

         this.zhankushow=false,
        this.redbookshow=false,
        this.wechatshow=false,
        this.douyinshow=true
      } else if (type == 3) {
                this.qctitle="请扫描下方二维码关注我们小红书"

         this.zhankushow=false,
        this.redbookshow=true,
        this.wechatshow=false,
        this.douyinshow=false
      } else if (type == 4) {
                this.qctitle="请扫描下方二维码关注我们公众号"

         this.zhankushow=false,
        this.redbookshow=false,
        this.wechatshow=true,
        this.douyinshow=false
      }
    },
    // 重载页面
    refresh() {
    // location.reload();
    this.isRouterActive=false;
    this.$nextTick(()=>{
      this.isRouterActive=true
    })   
    document.documentElement.scrollTop = 0;
    },
    // 点击图标路由跳转首页
    toHome() {
      if(this.currPage=="/Home"){
      console.log("在首页")  
      document.documentElement.scrollTop = 0;
      }else{
      console.log("不在首页")  
      this.$router.push("/Home");
      this.activeIndex="/Home"
      }
    },
    handleSelect(key, keyPath) {
    //   this.showHome=false
    //  this.isRouterActive=true 
    this.currPage=key
    this.activeIndex=key

    },
    async getSystemList() {
      let res = await this.$API.system.getSystemList();
      if (res.code == 200) {
        this.systemInfo = res.data;
      }
    },
    toworkList(type){
      if(this.currPage=="/WorkList"){
      console.log("在案例列表页")
      this.$router.push({name:'WorkList',query: {id:type}})
      //重载页面
      this.refresh()
      
      }else{
      console.log("不在案列列表页")  
      this.$router.push({name:'WorkList',query: {id:type}})
      this.currPage="/WorkList"
      }
    
    }
  },
  components:{
    // Home
    ScaleBox
  }
}
</script>

<style scoped>
.icon {
  display: flex;
  height: 32px;
  line-height: 32px;
  align-items: center;
  /* position:absolute;
  top:290px;
  left: 10px; */
}
.qctipe {
  text-align: left;
  height: 20px;
  font-size: 20px;
  font-family: SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #ffffff;
  line-height: 32px;
}
.qccode {
  margin: 20px 0;
  width: 166px;
  height: 170px;
}
.phone {
}
.phone-tit {
  margin: 0 0 20px 0;
  text-align: left;
  height: 38px;
  font-size: 38px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #ffffff;
  line-height: 38px;
}
.phone-num {
  text-align: left;
  height: 32px;
  font-size: 32px;
  font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #ffffff;
  line-height: 32px;
}
.tipe {
  /* position: absolute;
  top: 50px;
  left: 10px; */
}
.tipe-txt {
  text-align: left;
  height: 48px;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #ffffff;
  line-height: 48px;
}

#header {
    min-width:1920px;
  position: fixed;
  top: 0;
  z-index: 18;
  background-color: #000000;
  padding: 24px 11%;
  width: 100%;
  box-sizing: border-box;
  /* height: 104px; */
  display: flex;
  justify-content: space-between;
}
.el-menu-demo {
  flex-wrap: nowrap;
}
.el-menu-item {
  width: 20%;
  font-size: 18px;
}
.el-menu-item.is-active {
  border-bottom-color: #000000 !important;
}
.contact-box {
  box-sizing: border-box;
  margin: 100px auto;
  padding: 94px 91px 108px 81px;
  width: 1520px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.contact-img {
  width: 189px;
  height: 57px;
}
.contact-txt {
  width: 806px;
  height: 58px;
  font-size: 22px;
  text-align: left;
  font-family: SourceHanSansCN-Regular;
  font-weight: Regular;
  color: #828282;
  line-height: 36px;
}
.contact-btn {
  padding: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 172px;
  height: 62px;
  border-radius: 33.5px;
  background-color: #f55639;
}
.contact-btn-txt {
  text-align: center;
  height: 62px;
  line-height: 62px;

  color: #ffffff;
  font-size: 24px;
  font-family:  SourceHanSansCN-Medium;
  font-weight: Medium;
}
.footer {
  /* position:fixed;
    bottom:0; */
  width: 100%;
  height: 400px;
  /* background: linear-gradient(0deg, #484545 0%, #231815 100%); */
  background-color: #000000;
  padding: 80px 0  0 0 ;
  /* position: relative; */
}
.container {
  margin:0 auto;
  /* position: absolute;
  top: 78px;
  left: 315px; */
  width: 1520px;
  height: 350px;
  display: flex;
}
.content-left {
  width: 27%;
  /* position: relative; */
}
.content-center {
  width: 50%;

  border-right: solid 1px #ffffff;
  border-left: solid 1px #ffffff;
  padding: 0 100px;
  box-sizing: border-box;
}
.center-item {
  height: 57px;
  margin: 0 0 50px 0;
}
.center-item-tit {
  text-align: left;
  height: 22px;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: Normal;
  color: #ffffff;
  line-height: 22px;
}
.center-item-txt {
  margin-top: 10px;
  text-align: left;
  height: 16px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium;
  font-weight: Medium;
  color: #ffffff;
  line-height: 16px;
}
.content-right {
  width: 23%;
  padding: 0 80px;
  box-sizing: border-box;
}
</style>