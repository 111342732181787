<template>
     <div style="width: calc(100% - 67px); margin: 0 auto">
        <div class="txt1">最新动态</div>
        <div class="news-list">
          <div
            class="news-item"
            v-for="(item, index) in newsList.slice(0, 3)"
            :key="index"
            @click="toNewsDetail(item)"
          >
            <div class="news-top">
              <div class="news-img">
                <img
                  :src="item.cover"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
            </div>

            <div class="news-bottom">
              <div class="news-tit">{{ item.title }}</div>
              <!-- <div class="news-content">
                <div class="news-time">{{ item.subtitle }}</div>
                <div class="news-scord">
                  <div
                    style="width: 9px; height: 9px"
                    v-for="star in 5"
                    :key="star"
                  >
                    <img
                      src="../assets/star.png"
                      style="width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
       
      </div>
</template>

<script>
export default {
name:"MbNewsList",
data(){
    return{
    newsList:[]

    }
},
mounted(){
this.getNewsList()
},
methods:{
 async getNewsList() {
      let res = await this.$API.news.getNewsList();
      if (res.code == 200) {
        this.newsList = res.data;
        console.log("最新动态", this.newsList);
      }
    },
      toNewsDetail(item) {
      //  this.$router.push('/WorkDetail')
      this.$router.push({ name: "MbNewsDetail", query: { id: item.id } });
    },
}
}
</script>

<style scoped>
.txt1 {
  text-align: left;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  padding: 20px 0;
}
.news-list {
}
.news-item {
  width: calc(100% - 20px);
  margin: 10px;
}
.works-img {
  width: 100%;
  height: 85px;
  overflow: hidden;
}
.news-tit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* 禁止换行 */
  text-overflow: ellipsis; /* 超过一行时省略号代替 */
  margin: 0 0 30px 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #202016;
}

.works-time {
  /* margin:10px 0; */
  box-sizing: border-box;
  text-align: left;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}
.works-scord {
  display: flex;
  height: 9px;
  line-height: 9px;
}
.works-top {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.works-bottom {
}
.works-content {
  display: flex;
  justify-content: space-between;
}
.more{
  width:calc(100% - 208px);
  margin:20px auto;
}
</style>