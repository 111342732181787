<template>
<div style="margin-top:108px">
    <!-- 即刻联系→ -->
    <div style="margin:100px 0">
        <ContactNow/>
    </div>
    <!-- 案例鉴赏 -->
    <div class="works-box">
      <div class="box-top">
            <div>
              <div class="title">案例鉴赏</div>
              <div class="subtitle">
                <div class="txt" > SEE OUR WORKS</div>
                <div class="line" style="width: 150px;"></div>
              </div>
            </div>
            <div>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              text-color="#000"
              active-text-color="#FF8C26">
                <el-menu-item  @click="getWorksList" index="0">ALL </el-menu-item>

                <el-menu-item v-for="(item,index) in navList.slice( 0,7)" :key="index" @click="getworkListByClass(item)" :index="toStringindex(index)">{{item.class_name}}</el-menu-item>

            </el-menu>
            </div>
      </div>
      <div class="box-content">
          <div class="works-list">
              <div class="works-item" v-for="(item,index) in worksList" :key="index" @click="toworkDetail(item)">
                <div class="works-top">
                 <div class="works-img">
                  <img :src=item.cover style="width:100%;height:100%" alt="" class="fangda">
                </div>
                <div class="mask"></div>
                </div>
                
                <div class="works-bottom">
                  <div class="works-tit">{{item.title}}</div>
                  <div class="works-content" >
                    <div class="works-time">{{item.subtitle}}</div>
                    <div class="works-scord">
                         <div style="width:18px;height:18px" v-for="star in 5" :key="star">
                          <img src="../assets/star.png" style="width:100%;height:100%" alt="">
                         </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div class="box-bottom">
        <!-- <div class="more-btn">
          <img src="../assets/more.png" alt="">
        </div> -->
      </div>
    </div>
  <!-- 合作伙伴 -->
    <div class="partner-box">
      <div class="partner-tit">
        <div class="tit1">合作伙伴</div>
        <div class="tit2">COOPERATIVE PARTNER</div>
      </div>
      <div class="partner-list" >
        <div class="partner-item" v-for="index in 12" :key="index">
           <img alt="" :src="require('@/assets/logoList/logo' + index + '.png')" style="width:100%; height:100%">  
        </div>
      </div>
    </div>
        <ContactNow/>
       <div style="height:40px ;background-color:#ffffff;margin-top:40px">

    </div>

</div>
    
</template>

<script>
import ContactNow from "./ContactNow.vue"

export default {
name:"WorkList",
components: {
   ContactNow
    
  },
 data() {
      return {
        if_index:0,
        id:0,
        num:2,
       navList:[
         {
          name:'ALL'
        },
        {
          name:'IP吉祥物全案'
        },
        {
          name:'动画视频'
        },
        {
          name:'表情包设计'
        },
        {
          name:'长图条漫'
        },
        {
          name:'商插绘本'
        },
        {
          name:'文创礼盒'
        },
         {
          name:'平面设计'
        },
        {
          id:"07",
          name:'商业空间全案'
        },
      ],
      cardList:[
        {
          url:require("@/assets/card/card1.png"),
          tit:'多款设计方案多种设计风格'
        },
         {
          url:require("@/assets/card/card2.png"),
          tit:'坚持精品创意独创设计有保障'
        },
         {
          url:require("@/assets/card/card3.png"),
          tit:'以用户需求导向提升品牌竞争力'
        },
         {
          url:require("@/assets/card/card4.png"),
          tit:'一对一沟通总监全程跟进'
        },
      ],
      worksList:[
        {
          url:require("@/assets/main/main1.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:1
        },
         {
          url:require("@/assets/main/main2.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main3.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        }, {
          url:require("@/assets/main/main4.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:2
        }, {
          url:require("@/assets/main/main5.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:4
        }, {
          url:require("@/assets/main/main6.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:2
        }, {
          url:require("@/assets/main/main1.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:4
        }, {
          url:require("@/assets/main/main2.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        }, {
          url:require("@/assets/main/main3.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main4.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:1
        }, {
          url:require("@/assets/main/main5.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:3
        }, {
          url:require("@/assets/main/main6.jpg"),
          tit:'深圳保利剧院吉祥物设计',
          time:'完成于09月22日',
          scord:5
        },
      ],
      activeIndex: "0",
      value:500,
      };
    },
    methods: {
    toStringindex(index){
       let n=index+1
       let str=n.toString()
       return str
    },
    // 导航栏点击事件
    handleSelect(key, keyPath) {
      console.log("案例列表",key,keyPath)
      this.activeIndex=key
      this.$router.push({name:'WorkList',query: {id:this.activeIndex}})
    },
    toworkDetail(item){
        //  this.$router.push('/WorkDetail')
         this.$router.push({name:'WorkDetail',query: {id:item.id,workclass:item.work_class}})

      },

     
    async getWorkClassList() {
      let res = await this.$API.works.getWorkClassList();
      if (res.code == 200) {
        this.navList=res.data;
      }
    },
    async getWorksList() {
      let res= await this.$API.works.getworkListByClass({id:0},this.if_index)
      if (res.code == 200) {
        this.worksList=res.data;
      }
    },

    async getworkListByClass(item){
      let res= await this.$API.works.getworkListByClass(item,this.if_index)
      if (res.code == 200) {
        this.worksList=res.data;
      }
    },
      toTop() {

      document.documentElement.scrollTop = 0;

    },
    
    },
    mounted(){
      this.toTop();
      this.getWorkClassList()
      console.log("路由参数",this.$route.query.id)
      this.id=this.$route.query.id
      if(this.$route.query.id){
         this. getworkListByClass({id:this.id})
         this.activeIndex=this.$route.query.id
         console.log("this.activeIndex",this.activeIndex)
      }else{
      this.getWorksList()

      }
     
    }
}
</script>

<style scoped>
.el-menu-item{
  height:64px;
  font-size: 16px;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu-item.is-active {
  font-size:18px;
  color: #000;
  border-bottom: 2px solid #FF8C26;
}
.works-list{
width:1520px;
margin:20px auto; 
display: flex;
flex-wrap: wrap;
}
.works-item{
width: 411px;
margin:10px 45px;
/* padding:10px; */
box-sizing: border-box;
}
.works-img{
width: 411px;
height: 291px;
overflow: hidden;
}
.mask{
position:absolute;
top:0;
left:0;  
width: 411px;
height: 291px;
background-color: rgba(0, 0,0, 0.7);
display: none;
}
.works-top:hover .mask{
cursor: pointer;
display: block;
}
.fangda{
  transition: all 0.6s;
}
.works-top:hover .fangda{
transform: scale(1.2);
cursor: pointer;
}
.works-tit{
overflow: hidden;
white-space: nowrap; /* 禁止换行 */
text-overflow: ellipsis; /* 超过一行时省略号代替 */
margin:10px 0;
box-sizing: border-box;
text-align: left;
height: 26px;
font-size: 21px;
font-family: SourceHanSansCN-Regular;
font-weight: 400;
color: #202016;
line-height: 26px;
}
.works-time{
/* margin:10px 0; */
box-sizing: border-box;
text-align: left;
height: 18px;
font-size: 14px;
font-family: SourceHanSansCN-Regular;
font-weight: 400;
color: #727272;
line-height: 18px;
}
.works-scord{
display: flex;
height:18px;
line-height: 18px;

}
.works-top{
position: relative;
box-sizing: border-box;
width: 411px;
}
.works-bottom{
padding:0 10px ;
box-sizing: border-box;
}
.works-content{
display: flex;
justify-content: space-between;

}


.box-top{
  width:1520px;
  margin:20px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.title{
text-align: left;  
height: 37px;
font-size: 28px;
font-family: SourceHanSansCN-Bold;
font-weight: bold;
color: #212121;
line-height: 37px;

}
.subtitle{
position: relative;
width: 120px;
height: 31px;

}
.txt{
text-align: left; 
z-index:9;
position:absolute;
top:12px; 
width: 208px;
height: 14px;
font-size: 18px;
font-family: SourceHanSansCN-Medium;
font-weight: 500;
color: #333333;
line-height: 14px;

}
.line{
position:absolute;
bottom:0;
text-align: left;
height: 12px;
background: #FF8C26;
}
.partner-box{
margin:100px 0;
}
.partner-tit{

}
.tit1{
height: 34px;
font-size: 36px;
font-family:SourceHanSansCN-Medium;
font-weight: 500;
color: #333333;
line-height: 1px;
}
.tit2{
height: 19px;
font-size: 24px;
font-family: PingFang;
font-weight: 400;
color: #666666;
line-height: 1px;
}
.partner-list{
width: 1520px;  
margin: 100px auto;
display:flex;
justify-content: space-between;
flex-wrap: wrap;
}
.partner-item{
margin: 30px 0;  
width: 444px;
height: 119px;
background: #FFFFFF;
/* border: 1px solid #A0A0A0; */
border-radius: 58px;
}
</style>