<template>
  <div>
    <van-swipe :autoplay="3000" indicator-color="white" >
      <van-swipe-item
        default="1"
        v-for="(item, index) in bannerList.slice(0,5)"
        :key="index"
      >
        <div @click="toLink(item)">
          <img
            alt=""
            :src="item.banner_img"
            style="width: 100%; height: 100%"
          />
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "Mbbanner",
  mounted() {
    this.getBannerList();
  },
  data() {
    return {
      bannerList: [],
    };
  },
  methods: {
    //获取轮播图列表
    async getBannerList() {
      let res = await this.$API.banner.getBannerList();
      if (res.code == 200) {
        this.bannerList = res.data;
        console.log(this.bannerList);
      }
    },
    toLink(item) {
      console.log("轮播图点击事件");
      window.location.href = item.link;
    },
  },
};
</script>

<style scoped>

</style>