// 该页面专门用于创建整个应用的路由器
import VueRouter from 'vue-router';
import Home from '../components/Home'
import IndustryCase from '../components/IndustryCase'
import ServiceBusiness from '../components/ServiceBusiness'
import Introduction from '../components/Introduction'
import ContactUs from '../components/ContactUs'
import workDetail from '../components/workDetail'
import workList from '../components/workList'
import NewsDetail from'../components/NewsDetail'
import NewsList from'../components/NewsList'
import PcIndex from'../components/PcIndex'
import MbIndex from'../components/MbIndex'
import MbHome from '../components/MbHome'
import MbServiceBusiness from '../components/MbServiceBusiness'
import MbIntroduction from '../components/MbIntroduction'
import MbContactUs from '../components/MbContactUs'
import MbworkList from '../components/MbworkList'
import MbworkDetail from '../components/MbworkDetail'
import MbNewsDetail from '../components/MbNewsDetail'
import MbNewsList from '../components/MbNewsList'
import myApp from '../components/myApp'
import MbmyApp from '../components/MbmyApp'





  
//创建并暴露一个路由器
const router= new VueRouter({
    // mode: 'history',
    // base: '/home/',
    // base:function () {
    //     return window.location.pathname.replace('/my-app/', '') + '/my-app/';
    //     },
routes:[
    {
        path: '/',
        redirect:"/myApp"
    },
    {
        path: "/myApp", // pc端首页
        name: myApp,
        component: myApp,
    },
    {
        path: "/MbmyApp", // 移动端首页
        name: MbmyApp,
        component:  MbmyApp,
    },
    {
        path: "/PcIndex", // pc端首页
        name: PcIndex,
        component: PcIndex,
        redirect:"/Home",
        children:[
            {
                path:'/Home',
                component:Home,
                meta: {
                    title:'天降文化-南京创心天降文化创意有限公司-动漫IP品牌设计公司--吉祥物设计-插画设计-MG动画设计-绘本设计-漫画设计-表情包设计-画册设计-包装设计-logo设计-品牌设计'
                }
               
            },
            {
                path:'/IndustryCase',
                component:IndustryCase,
                meta: {
                    title:'行业案例-天降文化-南京吉祥物设计公司-吉祥物设计公司哪家好-吉祥物设计公司推荐-南京创心天降文化创意有限公司'
                }
            },{
                path:'/ServiceBusiness',
                component:ServiceBusiness,
                meta: {
                    title:'服务业务-天降文化-动漫IP品牌设计公司--吉祥物设计-插画设计-动画设计-绘本设计-漫画设计-表情包设计-画册设计-包装设计-logo设计-品牌设计业'
                }
            },{
                path:'/Introduction',
                component:Introduction,
                meta: {
                    title:'公司简介-南京创心天降文化创意有限公司'
                }
            },{
                path:'/ContactUs',
                component:ContactUs,
                meta: {
                    title:'联系我们-南京动漫IP品牌设计公司电话地址-南京创心天降文化创意有限公司'
                }
            },
            {
                path:'/WorkList',
                name:'WorkList',
                component:workList,
                meta: {
                    title:'案例'
                }
            },
            {
                path:'/WorkDetail',
                name:'WorkDetail',
                component:workDetail,
                meta: {
                    title:'案例详情'
                }
            },
            {
                path:'/NewsList',
                name:'NewsList',
                component:NewsList,
                meta: {
                    title:'最新动态列表'
                }
            },
            {
                path:'/NewsDetail',
                name:'NewsDetail',
                component:NewsDetail,
                meta: {
                    title:'最新动态详情页'
                }
            },
        ]

      },
      {
        path: '/MbIndex', // 移动端首页
        name: MbIndex,
        component: MbIndex,
        redirect:"/MbHome",
        children:[
            {
                path:'/MbHome',
                component:MbHome,
                meta: {
                    title:'天降文化-南京创心天降文化创意有限公司-动漫IP品牌设计公司--吉祥物设计-插画设计-MG动画设计-绘本设计-漫画设计-表情包设计-画册设计-包装设计-logo设计-品牌设计'
                }
               
            },
           {
                path:'/MbServiceBusiness',
                component:MbServiceBusiness,
                meta: {
                    title:'服务业务-天降文化-动漫IP品牌设计公司--吉祥物设计-插画设计-动画设计-绘本设计-漫画设计-表情包设计-画册设计-包装设计-logo设计-品牌设计业'
                }
            },{
                path:'/MbIntroduction',
                component:MbIntroduction,
                meta: {
                    title:'公司简介-南京创心天降文化创意有限公司'
                }
            },{
                path:'/MbContactUs',
                component:MbContactUs,
                meta: {
                    title:'联系我们-南京动漫IP品牌设计公司电话地址-南京创心天降文化创意有限公司'
                }
            },
            {
                path:'/MbworkDetail',
                name:'MbworkDetail',
                component:MbworkDetail,
                meta: {
                    title:'案例详情'
                }
            },
             {
                path:'/MbworkList',
                name:'MbworkList',
                component:MbworkList,
                meta: {
                    title:'案例'
                }
            },
            {
                path:'/MbNewsList',
                name:'MbNewsList',
                component:MbNewsList,
                meta: {
                    title:'最新动态列表'
                }
            },
            {
                path:'/MbNewsDetail',
                name:'MbNewsDetail',
                component:MbNewsDetail,
                meta: {
                    title:'最新动态列表'
                }
            },
        ]

      },

 
  
   
   
]
    })
    router.beforeEach((to,from,next) => {
        /* 路由发生变化修改页面title */
        if(to.meta.title){
          window.document.title = to.meta.title;
        }
        next();
      })  
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
    export default router
 