import request from '@/utils/request'

export const getSystemList=()=>request({
    url:'/system_info',
    method:'get',
})
// export function getSystemList() {
//     return request({
//         url: '/system_info',
//         method: 'get',
        
//     })
// }





// export const updateSystem=(system)=>request({
//     url:'/system_edit',
//     method:'post',
//     data:system
// })
