import request from '@/utils/request'


// 查看服务业务信息
export const getBusinessList=()=>request({
    url:'/service_business_list ',
    method:'get',
})
// 上传图片
export const getPhoto=(photo)=>request({
    url:'/upload_img',
    method:'post',
    data:photo
})

// 添加服务业务信息
export const addBusiness=(business)=>request({
    url:'/service_business_add',
    method:'post',
    data:business
})

// 修改服务业务信息
export const updateBusiness=(business)=>request({
    url:'/service_business_edit',
    method:'post',
    data:business
})

// 删除服务业务信息
export const deleteBusiness=(business)=>request({
    url:'/service_business_del',
    method:'post',
    data:{id:business.id}
})

export const addOrUpdateBusiness=(business)=>{
    if(business.id){
        return  request({url:'/service_business_edit',method:'post', data:business})
    }else{
        return  request({url:'/service_business_add',method:'post', data:business})

    }
}
